import React from "react";
import { Tag } from "../components/utilityComps.js";
import { cdelay, getv, iso, jstr } from "./utils.js";
import { q_send_crash_report } from "../queries/queries.js";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      err_msg: "",
      err_whole: "",
      err_stack: "",
      retry: 0,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      err_msg: error.message,
      err_whole: error.toString(),
    };
  }

  componentDidCatch(error, errorInfo) {
    console.log("componentDidCatch:retry", this.state.retry);
    this.setState({
      err_stack: errorInfo.componentStack,
      retry: this.state.retry + 1,
    });
  }

  sendCrashReport = async () => {
    let vault = getv(window.ethereum, "selectedAddress");
    let doc = { vault, ...this.state, path: window.location.pathname };
    doc.id = "test";
    let resp = await q_send_crash_report({ doc }).queryFn();
    await cdelay(2 * 1e3);
    return getv(resp, "result");
  };

  render() {
    if (this.state.hasError) {
      if (this.props.err_comp)
        return (
          <>
            {React.cloneElement(this.props.err_comp, {
              sendCrashReport: this.sendCrashReport,
              err_msg: this.state.err_msg,
              err_stack: this.state.err_stack,
              err_whole: this.state.err_whole,
            })}
          </>
        );
      else return <p className="text-red-400">{this.state.err_msg}</p>;
    }
    return this.props.children;
  }
}
export const ErrTag = () => <Tag className={"bg-red-500"}>err</Tag>;
