import _ from "lodash";
import { emp, getv, nils } from "./utils.js";
import { twMerge } from "tailwind-merge";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const gen_valob_from_filters = (filters) => {
  return _.chain(filters)
    .entries()
    .map(([k, v]) => {
      return [k, v?.vals];
    })
    .fromPairs()
    .value();
};

export const gen_filters_from_valob = (filters, valob) => {
  return _.chain(filters)
    .entries()
    .map(([k, v]) => {
      v.vals = valob[k];
      return [k, v];
    })
    .fromPairs()
    .value();
};

export const gen_filters_cleared = (filters) => {
  return _.chain(filters)
    .entries()
    .map(([k, v]) => {
      v = _.cloneDeep(v);
      if (["range"].includes(v.type)) v.vals = { mi: null, mx: null };
      else if (["options", "options-only-ar"].includes(v.type)) v.vals = [];
      else if (["only", "options-only"].includes(v.type)) v.vals = null;
      else v.vals = null;
      return [k, v];
    })
    .fromPairs()
    .value();
};

export const gen_filter_state_ob = (
  filterob = {},
  keys = [],
  locfiltvalob = {},
) => {
  let o = {};
  if (nils(keys)) return o;
  for (let krow of keys) {
    if (nils(krow)) continue;
    let [k, ex = {}] = krow;
    if (nils(filterob[k])) {
      // console.log(k, "filtob key not found");
    }
    let v = _.cloneDeep(filterob[k]);
    let vals = locfiltvalob[k];
    v = { ...v, ...ex };
    if (!nils(vals)) {
      v.vals = vals;
    }
    o[k] = v;
    if (ex.useby === true) {
      o[`${k}_by`] = {
        type: "mimx",
        vals: getv(locfiltvalob, `${k}_by`),
      };
    }
  }
  return o;
};

export const filt_ar_using_filters = ({ ar, filters }) => {
  try {
    // console.log("filt_ar_using_filters", ar, filters);
    const filt = _.filter(ar, (h) => {
      let p = 0;
      // if (p) console.log(bike);
      const { hid } = h;
      for (let [fkey, fob] of _.entries(filters)) {
        let { vals: f, path: k, cfilter = true } = fob;
        if (cfilter == false) continue;
        // console.log("filt_ar_using_filters",filters.in_arena);

        if (nils(f)) continue;
        if (_.isFunction(k)) {
          // console.log('fn', k, f)
          let resp = k(h, f, filters);
          if (resp == false) return false;
          else continue;
        } else if (_.isArray(f)) {
          let val = getv(h, k);
          if (_.isEmpty(f));
          else if (!f.includes(val)) {
            if (p) console.log(hid, "na array", k, val);
            return false;
          }
        } else {
          let val = getv(h, k);
          let { mi = undefined, mx = undefined } = f;
          val = parseFloat(val);
          if (p) console.log(k, { mi, mx });
          if (mi == undefined && mx == undefined);
          else if (mx !== undefined && val > mx) {
            if (p) console.log(hid, "na mx", k, val);
            return false;
          } else if (mi !== undefined && val < mi) {
            if (p) console.log(hid, "na mi", k, val);
            return false;
          } else if (!_.inRange(val, mi || 1e-5, (mx || 1e5) + 1e-8)) {
            if (p) console.log(hid, "na range", k, val);
            return false;
          }
        }
      }
      if (p) console.log("###", hid, "valid");
      return true;
    });
    return filt;
  } catch (err) {
    console.log(err);
    return ar;
  }
};

export const filt_ar_using_valfilt = ({ ar, valfilt, filtmap = {} }) => {
  try {
    // console.log("filt_ar_using_filters", ar, filters);
    const filt = _.filter(ar, (h) => {
      let p = 0;
      // if (p) console.log(bike);
      const { hid } = h;
      for (let [fkey, filtconf] of _.entries(filtmap)) {
        let { path: k, cfilter = true } = filtconf || {};
        // console.log("filt_ar_using_valfilt", { fkey, filtconf, k, cfilter });
        let f = valfilt[fkey];
        if (cfilter == false) continue;

        if (nils(f)) continue;
        if (_.isFunction(k)) {
          // console.log('fn', k, f)
          let resp = k(h, f, valfilt);
          if (resp == false) return false;
          else continue;
        } else if (_.isArray(f)) {
          let val = getv(h, k);
          if (_.isEmpty(f));
          else if (!f.includes(val)) {
            if (p) console.log(hid, "na array", k, val);
            return false;
          }
        } else {
          let val = getv(h, k);
          let { mi = undefined, mx = undefined } = f;
          val = parseFloat(val);
          if (p) console.log(k, { mi, mx });
          if (mi == undefined && mx == undefined);
          else if (mx !== undefined && val > mx) {
            if (p) console.log(hid, "na mx", k, val);
            return false;
          } else if (mi !== undefined && val < mi) {
            if (p) console.log(hid, "na mi", k, val);
            return false;
          } else if (!_.inRange(val, mi || 1e-5, (mx || 1e5) + 1e-8)) {
            if (p) console.log(hid, "na range", k, val);
            return false;
          }
        }
      }
      if (p) console.log("###", hid, "valid");
      return true;
    });
    return filt;
  } catch (err) {
    console.log(err);
    return ar;
  }
};

export const mainfiltbtn =
  "transition duration-500 resp-text--2 font-digi font-semibold  text-center mb-3 ";

export const sort_fn = (i1, path, type = "txt", ops) => {
  let a = getv(i1, path);
  if (type == "n") {
    if (nils(a)) return null;
    return +parseFloat(a);
  } else if (type == "txt") {
    if (nils(a)) return null;
    return a;
  } else if (type == "idx") {
    let idx_ar = ops;
    let idx = _.indexOf(idx_ar, a);
    return idx == -1 ? null : idx;
  } else if (type == "fn_n") {
    let fn_n = ops;
    a = fn_n(i1);
    return a;
  } else return null;
};
export const get_sort_sign = (sort) => {
  if (!sort) return -1;
  return sort[0] == "+" ? +1 : -1;
};

export const SortHead = ({ sorts, set_sorts, sort_ob, k, className }) => {
  let ob = sort_ob[k];
  let { disp = k } = ob || {};
  let [asc, desc] = [`+${k}`, `-${k}`];
  let cn = `flex flex-row items-center justify-center cursor-pointer inline-block`;
  let txtcn = "resp-text--2";
  const asc_clk = () => set_sorts([..._.filter(sorts, (i) => i != asc), desc]);
  const rem = () =>
    set_sorts([..._.filter(sorts, (i) => ![asc, desc].includes(i))]);
  const desc_clk = () => set_sorts([..._.filter(sorts, (i) => i != desc), asc]);
  if (sorts?.includes(asc))
    return (
      <div onDoubleClick={rem} onClick={asc_clk} className={cn}>
        <span className={twMerge(txtcn, className)}>{disp}</span>
        <span className="text-yellow-500">
          <FontAwesomeIcon icon={faArrowDown} />
        </span>
      </div>
    );
  else if (sorts?.includes(desc))
    return (
      <div onDoubleClick={rem} onClick={desc_clk} className={cn}>
        <span className={twMerge(txtcn, className)}>{disp}</span>
        <span className="text-yellow-500">
          <FontAwesomeIcon icon={faArrowUp} />
        </span>
      </div>
    );
  else
    return (
      <div onDoubleClick={rem} onClick={desc_clk} className={cn}>
        <span className={twMerge(txtcn, className)}>{disp}</span>
        <span className="text-transparent">
          <FontAwesomeIcon icon={faArrowUp} />
        </span>
      </div>
    );
};

export const sort_listob = ({ list = [], sort_ob, sorts, id = "hid" }) => {
  try {
    if (_.isEmpty(list)) return [];

    let ar = _.cloneDeep(_.values(list));
    // let ar_ob = _.chain(ar).keyBy(id).value();

    if (_.isEmpty(sorts)) return _.map(ar, (row) => row[id]);

    let sorts_n = sorts?.map((sort) => {
      let [sign, k] = [sort[0], sort.slice(1)];
      return [k, sign == "+" ? "asc" : "desc"];
    });

    let ord = [...sorts_n].reverse();
    ar = _.chain(ar)
      .orderBy(
        ord.map(([k, dir]) => (row) => {
          let fn = sort_ob[k]?.fn || null;
          let a = fn ? fn(row) : null;
          if (a == null) {
            if (dir == "asc") return 1e14;
            else return -1e14;
          }
          return a;
        }),
        ord.map(([k, dir]) => dir),
      )
      .map((row) => getv(row, id))
      .value();
    return ar;
  } catch (err) {
    console.log(err);
    let ids = list.map((row) => getv(row, id));
    return ids;
  }
};

export const sort_list = ({ list = [], sort_ob, sorts, id = "hid" }) => {
  try {
    if (_.isEmpty(list)) return [];

    let ar = _.cloneDeep(list);

    if (_.isEmpty(sorts)) return ar;

    let sorts_n = sorts?.map((sort) => {
      let [sign, k] = [sort[0], sort.slice(1)];
      return [k, sign == "+" ? "asc" : "desc"];
    });

    let ord = [...sorts_n].reverse();
    ar = _.chain(ar)
      .orderBy(
        ord.map(([k, dir]) => (row) => {
          let fn = sort_ob[k]?.fn || null;
          let a = fn ? fn(row) : null;
          console.log("sort_list:fn", k, dir, a);
          if (a == null) {
            if (dir == "asc") return 1e14;
            else return -1e14;
          }
          return a;
        }),
        ord.map(([k, dir]) => dir),
      )
      .value();
    return ar;
  } catch (err) {
    console.log(err);
    return list;
  }
};

export const match_cri_to_bikedata = (cri, h) => {
  // console.log("cri", cri, h);
  for (let k of ["type", "element", "gender"]) {
    let fs = cri[k];
    if (emp(fs)) continue;
    let mat = fs.includes(h[k]);
    // console.log("cri", k, fs, h[k], mat);
    if (!mat) return false;
  }

  if (!emp(cri.fno)) {
    let { mi = 1, mx = 900 } = cri.fno;
    let inrange = _.inRange(h.fno, mi, mx + 0.1);
    // console.log("cri.fno", cri.fno, h.fno, inrange);
    if (!inrange) return false;
  }

  return true;
};
