import {
  faCheck,
  faCheckCircle,
  faCircleInfo,
  faExclamationCircle,
  faExclamationTriangle,
  faSpinner,
  faTimesCircle,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import {
  cdelay,
  copy_clip,
  dec,
  getv,
  nils,
  toeth,
  tofeth,
  trim2,
} from "../utils/utils";
import {
  au_wait_for_tx,
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "./contract_funcs";
import { tokdecn } from "../App";
import _ from "lodash";
import { Link } from "react-router-dom";
import { CopyBtn } from "../components/ShortComps";
import { PayEmbed } from "thirdweb/react";
import { polygon } from "thirdweb/chains";

export const HashToColors = ({ hash, contcn = "", cn = "" }) => {
  const colors = useMemo(() => {
    return hash
      .slice(2)
      .match(/.{1,6}/g)
      .map((chunk) => `#${chunk.padEnd(6, "0")}`);
  }, [hash]);

  const [show, set_show] = useState(false);

  const copy = () => {
    copy_clip(hash);
    set_show(true);
    setTimeout(() => {
      set_show(false);
    }, 1.5 * 1e3);
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        copy();
      }}
      className={twMerge(
        "w-max grid grid-cols-6 relative overflow-visible cursor-pointer",
        contcn,
      )}
    >
      <div
        class={twMerge(
          "absolute left-[0] resp-p-1 top-[-20%] h-[0] w-[0]",
          "pointer-events-none",
        )}
      >
        <div
          class={twMerge(
            "fr-sc w-max resp-gap-1 resp-py-1 resp-px-3 font-digi  rounded-full text-green-400  bg-slate-700 resp-text--1 border border-green-400",
            show
              ? "opacity-100 translate-x-[0px]"
              : "opacity-0 translate-x-[-50px]",
            "transition-all duration-300",
          )}
        >
          <FontAwesomeIcon icon={faCheck} className="" />
          <span>Copied!!</span>
        </div>
      </div>
      {colors.map((color, index) => (
        <div
          className={twMerge("w-[1rem] aspect-square", cn)}
          key={index}
          style={{ backgroundColor: color }}
        ></div>
      ))}
    </div>
  );
};

const tx_sender = {
  wallet: (con, method, args) => {},
  thirdweb: (con, method, args) => {},
};

export class LogBox {
  constructor(
    { vault, token, aumode, appcon, t3con, logbox, set_logbox },
    default_ext,
  ) {
    this.vault = vault;
    this.token = token;
    this.aumode = aumode;
    this.logbox = logbox;
    this.set_logbox = set_logbox;
    this.appcon = appcon;
    this.t3con = t3con;
    this.default_ext = default_ext;
    this.precn = tokdecn(token);
    return;
  }

  async init() {
    this.set_logbox((d) => ({
      ...d,
      steps: [],
      action_btns: [],
      status: "loading",
    }));

    this.token_con = await (this.aumode == "thirdweb"
      ? t3_asset_signer(this.token)
      : mm_asset_signer(this.token));
  }

  async add_step(step, action_btns = []) {
    // console.log("add_step", step);
    this.set_logbox((d) => ({
      ...d,
      action_btns,
      steps: [...(d?.steps ?? []), step],
      status: "loading",
    }));
  }
  async edit_last_step(step, action_btns = []) {
    this.set_logbox((d) => {
      let o = { ...d, steps: d.steps, action_btns };
      o.steps[o.steps.length - 1] = step;
      o.status = "loading";
      return o;
    });
  }
  async rem_steps(n) {
    this.set_logbox((d) => {
      let o = { ...d, steps: d.steps.slice(0, d.steps.length - n) };
      return o;
    });
  }

  usd_to_tok(amt_usd, ext = this.default_ext) {
    this.amt_usd = parseFloat(amt_usd);
    let fn = this.appcon.usd_to_tok_val;
    this.amt_tok = fn(this.amt_usd, this.token);
    // console.log("usd_to_tok", amt_usd, this.amt_tok, this.default_ext);
    if (ext.update_log) {
      this.add_step([
        "info",
        `Amt: ${dec(amt_usd, 2)} USD --> ${dec(this.amt_tok, this.precn)} ${this.token}`,
      ]);
    }
    return this.amt_tok;
  }

  async get_balance(args = [], ext = this.default_ext) {
    let bal = await this.token_con.balanceOf(this.vault);
    let precn = tokdecn(this.token);
    bal = parseFloat(dec(tofeth(bal), tokdecn(this.precn)));
    this.balance = bal;
    if (ext.update_log) {
      this.add_step(["info", `Balance: ${dec(bal, this.precn)} ${this.token}`]);
    }
    return bal;
  }

  async send_er20_base_txn(method, args = [], ext = this.default_ext) {
    // console.log("token_con.contrac", this.token_con.approve);
    if (this.aumode == "thirdweb") {
      let tx = await t3_contract_call(this.token, method, args, "txn", false, {
        active_account: this.t3con.active_account,
      });
      return tx;
    } else {
      let tx = await this.token_con[method](...args);
      return tx;
    }
  }

  async get_allowance(args = [], ext = this.default_ext) {
    let [allowance_to] = args;
    let alw = await this.token_con.allowance(this.vault, allowance_to);
    // console.log("get_allowance", this.vault, allowance_to, alw);
    alw = parseFloat(dec(tofeth(alw), tokdecn(this.precn)));
    if (ext.update_log) {
      this.add_step([
        "info",
        `Allowance: ${dec(alw, this.precn)} ${this.token}`,
      ]);
    }
    return alw;
  }

  async approve(args = [], ext = this.default_ext) {
    let [allowance_to, amt] = args;
    if (ext.update_log) {
      this.add_step(["action", "Confirm Transaction for Contract Approval"]);
      await cdelay(1 * 1e3);
    }
    let apv_args = [allowance_to, toeth(dec(amt, this.precn))];
    let method = "approve";
    let tx = await this.send_er20_base_txn(method, apv_args, ext);
    if (ext.update_log) {
      this.edit_last_step([
        "loading",
        `Approving for ${dec(amt, this.precn)} ${this.token}`,
      ]);
    }
    // if (ext.wait === true)
    tx = await au_wait_for_tx(tx, { aumode: this.aumode });
    if (ext.update_log) {
      this.edit_last_step(["success", "Approved"]);
    }
    return tx;
  }

  async tok_bal_alw_apv([allowance_to, amt], ext = this.default_ext) {
    try {
      let bal = await this.get_balance([], ext);
      if (bal < amt) {
        let errmsg = `Low Balance: ${dec(bal, this.precn)} ${this.token}`;
        setTimeout(() => {
          // console.log("open_t3_buyembed", { token: this.token, amt });
          this.t3con.open_t3_buyembed({
            token: this.token,
            amt: dec(amt, this.precn),
            headmsg: `Falling short on funds for DNA_Challenge \nAmt Paying:${dec(amt, this.precn)} ${this.token}`,
          });
        }, 2 * 1e3);
        throw new Error(errmsg);
      }

      let alw = await this.get_allowance([allowance_to], ext);
      // console.log({ alw, amt });
      if (alw < amt) {
        // console.log("amt", amt, "alw", alw);
        let tok1usdval = window.global_token.usd_to_tok_val(1, this.token);
        let reqalw = amt + tok1usdval;
        let apv = await this.approve([allowance_to, reqalw], {
          ...ext,
          wait: true,
        });
        console.log("apv", apv);
        await cdelay(1 * 1e3);
      }
      return { amt_tok: amt, done: true };
    } catch (err) {
      let errmsg = this.handle_err(err);
      return { err, errmsg };
    }
  }

  handle_err = (err) => {
    let steps = getv(this.logbox, "steps");
    if (nils(steps)) {
      console.log("err no steps, probably user reset changes");
      return;
    }
    let errmsg = !nils(err.reason) ? err.reason : err.message;
    this.set_logbox((d) => {
      let o = { ...d, steps: d.steps, action_btns: [] };
      o.steps[o.steps.length - 1] = ["error", errmsg];
      o.status = "error";
      return o;
    });
    return errmsg;
  };

  async usd_bal_alw_apv([allowance_to, amt_usd], ext = this.default_ext) {
    let fn = this.appcon.usd_to_tok_val;
    let amt_tok = fn(this.amt_usd, this.token);
    let resp = await this.tok_bal_alw_apv([allowance_to, amt_tok], ext);
    return { amt_usd, ...resp };
  }

  async send_cv2_tx(
    [con_class, method, args, txinfo = {}, msg_ob],
    ext = this.default_ext,
  ) {
    try {
      this.add_step(["action", msg_ob.action]);

      let con_initparams = {
        aumode: this.aumode,
        active_account: this.t3con.active_account,
      };
      let con = await con_class.get_contract(con_initparams);
      // console.log("con", con);
      let txfn = getv(con, method);
      if (nils(txfn)) {
        this.edit_last_step(["error", `Method: ${method} not found`]);
        return;
      }
      let tx = await con[method](...args, { ...txinfo, wait: false });
      tx = au_wait_for_tx(tx, { aumode: this.aumode });
      this.edit_last_step(["loading", msg_ob.loading]);
      tx = await tx;
      if (!nils(tx.hash) && _.isFunction(msg_ob.success)) {
        msg_ob.success(tx);
      }
      // this.edit_last_step(["success", msg_ob.success(tx)]);

      if (con.getParsedLogs) {
        let parsedlogs = await con.getParsedLogs(tx.logs);
        tx.parsedlogs = parsedlogs;
      }

      return tx;
    } catch (err) {
      let errmsg = this.handle_err(err);
      return { err, errmsg };
    }
  }

  render_step_row(step, ext) {
    let [type, msg, contprops, link] = step;

    let [icon, icn] = getv(step_icon_map, type, ["", ""]);

    let inner_jsx =
      type == "jsx" ? (
        <div class="">{msg}</div>
      ) : (
        <>
          <div class="fr-sc w-full resp-gap-2">
            <FontAwesomeIcon
              className={twMerge("resp-text-2 ", icn)}
              icon={icon}
            />
            <p class="resp-text-0">{msg}</p>
          </div>
        </>
      );
    if (!nils(link)) {
      let sameorigin = link[0] == "/";
      if (sameorigin) {
        return (
          <Link to={link} {...contprops}>
            {inner_jsx}
          </Link>
        );
      } else {
        return (
          <a href={link} target="_blank" {...contprops}>
            {inner_jsx}
          </a>
        );
      }
    } else {
      return <div {...contprops}>{inner_jsx}</div>;
    }
  }
}

const step_icon_map = {
  info: [faCircleInfo, "text-blue-400"],
  action: [faTriangleExclamation, "text-orange-400"],
  success: [faCheckCircle, "text-green-400"],
  loading: [faSpinner, "text-blue-400 spin-anim"],
  warning: [faExclamationCircle, "text-yellow-400"],
  error: [faTimesCircle, "text-red-400"],
};
