import {
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faArrowRightLong,
  faCheck,
  faCheckCircle,
  faSpinner,
  faSquare,
  faUndoAlt,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useContext,
  useState,
  useMemo,
  useEffect,
  createContext,
  useRef,
} from "react";
import { twMerge } from "tailwind-merge";
import { useAppContext, tokdecn, useNowContext } from "../../App";
import { PopUp, PopupCloseBtn } from "../../components/popup";
import { Card, InpText, Tag, TokenIcon } from "../../components/utilityComps";
import {
  qissuccesss,
  q_hstats,
  useStepQuery,
  q_quest_create,
  polytxnlink,
  q_logtxnqueue,
  q_quest_mark_enter,
  q_roadmap_entry_tickets,
  polytxnidlink,
  q_race,
  q_que_txn,
} from "../../queries/queries";
import {
  get_racestake_contract,
  get_racestake_key,
  RaceR2_HRow,
  season_cbs,
  SkewBtn,
  SkewWrap2,
  StatType_Switches,
} from "../../utils/raceutils2";
import {
  cdelay,
  dec,
  getv,
  iso,
  json_to_base64,
  jstr,
  nils,
  toeth,
  tofeth,
  trim2,
} from "../../utils/utils";
import { useAccountContext } from "../../wrappers/AccountWrapper";
import _, { curryRight } from "lodash";
import { Loader01c } from "../../components/anims";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../../contracts/contract_funcs";
import { extract_inp } from "../../components/input";
import { useThirdWebLoginContext } from "../ThirdWebLogin";
import { useAuthContext } from "../../wrappers/AuthWrapper";
import { cb_txt } from "../../utils/cn_map";
import { useQueries } from "react-query";
import { get_roadmap_status } from "../Roadmap";
import { useNavigate } from "react-router";
import { polygon } from "thirdweb/chains";
import { LogBox } from "../../contracts/txns_logbox_helper";
import { contractAddress_list } from "../../contracts/constants";
import { DNA_PayReceiver } from "../../contracts/v2/DNA_PayReceiver";

const allowed_fees = [1, 2.5, 5, 10];
const valid_vaults = (vault) => {
  return [
    "0xaf1320faa9a484a4702ec16ffec18260cc42c3c2",
    "0xa0d9665e163f498082cd73048da17e7d69fd9224",
  ].includes(vault);
};

export const QuestSelect = ({
  custom_txt_fns = {},
  override_info = {},
  post_enterrace,
  blocked_options = [],
  block_url_update = false,
  init_stage = 0,
  card_cn = "bg-r2lig/20 backdrop-blur-md border border-acc4",
}) => {
  const history = useNavigate();

  const appcon = useAppContext();
  const aucon = useAuthContext();
  const accon = useAccountContext();
  const t3con = useThirdWebLoginContext();

  const { hids, vault, bikesob, roadmap_post_enter_race, refresh_roadmap } =
    accon;
  const { psearch, upd_psearch } = appcon;
  const { auth, aumode } = aucon;

  const falias = getv(psearch, "falias");
  const stage_opsmap = {
    0: "rvmode",
    1: "cb",
    2: "feeusd",
    3: "hid",
    4: "quest_type",
  };

  const { active_account, thirdweb_client } = useThirdWebLoginContext();

  const mxstage = 7;
  const get_prev_allowed_stage = (stage) => {
    if (stage <= init_stage) return init_stage;
    let mx = stage - 1;
    do {
      let is_blocked = blocked_options.includes(stage_opsmap[mx]);
      if (is_blocked) mx--;
      else break;
    } while (true);
    return mx;
  };
  const get_next_allowed_stage = (stage) => {
    if (stage >= mxstage) return mxstage;
    let nx = stage + 1;
    do {
      let is_blocked = blocked_options.includes(stage_opsmap[nx]);
      if (is_blocked) nx++;
      else break;
    } while (true);
    return nx;
  };

  const [stage, set_stage] = useState(init_stage);
  const [data, set_data] = useState({
    ...(!_.isEmpty(override_info) ? override_info : {}),
  });
  const [logbox, set_logbox] = useState({});

  const [entry_ticket, set_entry_ticket] = useState(null);
  const cancelref = useRef(null);

  const is_trial = falias == "roadmap";
  const [qotickets] = useQueries([
    q_roadmap_entry_tickets({ vault }, { enabled: !nils(vault) && is_trial }),
  ]);
  const [rem_tickets] = useMemo(() => {
    if (!is_trial) return [];
    let tickets = getv(qotickets, "data.result.tickets", []);
    let rem_tickets = tickets.filter((t) => t.used !== true);
    if (nils(entry_ticket) && !_.isEmpty(rem_tickets)) {
      let rem = _.shuffle(rem_tickets)[0];
      set_entry_ticket(rem);
    }
    return [rem_tickets];
  }, [is_trial, jstr(qotickets), jstr(qotickets.dataUpdatedAt)]);
  const rem = { stage };
  useEffect(() => {
    if (block_url_update) return;
    upd_psearch(rem);
  }, [jstr(rem)]);

  // useEffect(() => {
  //   console.log("stage", stage, data);
  // }, [stage, jstr(data)]);

  const go_prev = () => {
    set_stage(get_prev_allowed_stage(stage));
  };
  const go_next = () => {
    set_stage(get_next_allowed_stage(stage));
  };

  const headcn =
    "w-full my-[2rem] text-center font-digi resp-text-2 text-white";

  useEffect(() => {
    if (!nils(override_info?.k) && override_info.skipstages.includes(stage)) {
      go_next();
    }
  }, [stage, jstr(override_info)]);

  const reset_fn = () => {
    set_data({});
    set_logbox({});
    set_stage(0);
    cancelref.current = true;
  };

  const validate_next = useMemo(() => {
    if (stage == 0) {
      if (nils(data.rvmode)) return false;
    } else if (stage == 1) {
      if (nils(data.cb)) return false;
    } else if (stage == 2) {
      if (nils(data.feeusd)) {
        return false;
      }
    }
    if (stage == 3) {
      if (nils(data.hid)) {
        if (override_info?.k == "trainer") {
          let hid = getv(override_info, "hid");
          let h = getv(override_info, "h");
          set_data((o) => ({ ...o, hid, h }));
          go_next();
        }
        return false;
      }
    }
    if (stage == 4) {
      if (nils(data.quest_type)) return false;
    }
    if (stage >= mxstage) return false;
    return true;
  }, [stage, jstr(data)]);

  const get_active_cn = (k, op) => {
    const val = getv(data, k);
    const selected = op == val;
    const cn = nils(val) || selected ? "opacity-100" : "opacity-50";

    const text_cn = selected ? "italic text-acc0" : "";
    return [cn, text_cn];
  };

  const payout_fac = 5.12;
  const gen_payout = (fee) => {
    const payout = parseFloat(getv(data, "feeusd")) * payout_fac;
    return dec(payout, 2);
  };

  const qshs = useStepQuery({
    q_: q_hstats,
    par_ar: (hids || []).map((hid) => [
      { hid, cb: data.cb, rvmode: data.rvmode, class: 90 },
    ]),
    lim: 3,
    options: { enabled: !nils(data.cb) },
  });

  const [st_type, set_st_type] = useState("cb");
  const [st_paid, set_st_paid] = useState("paid");
  const [hs, hsob] = useMemo(() => {
    // console.log("hsstats:memo", hids, qshs);
    let hs = [];
    for (let q of qshs.qs) {
      if (!qissuccesss(q)) continue;
      let h = getv(q, "data.result");
      hs.push(h);
    }
    // console.log("hstats", hs);
    return [hs, _.keyBy(hs, "hid")];
  }, [jstr(_.map(qshs.qs, "dataUpdatedAt"))]);

  const [respdata, set_respdata] = useState({});
  useEffect(() => {
    console.log("respdata", respdata);
  }, [jstr(respdata)]);
  // const pay_quest = async (r) => {
  //   try {
  //     console.log("pay_quest", vault, r);
  //     let { rid, version, paytoken, fee } = r;
  //
  //     let rcon_ext =
  //       aumode == "thirdweb" ? { rpc: polygon.rpc, nosigner: true } : {};
  //     let rcon = await get_racestake_contract({ version, ...rcon_ext });
  //     if (nils(rcon)) throw new Error("racestake contract not found");
  //
  //     set_respdata({ status: "loading", msg: "Checking Balance..." });
  //
  //     await cdelay(1 * 1e3);
  //     let paycon =
  //       aumode == "thirdweb"
  //         ? await t3_asset_signer(paytoken)
  //         : await mm_asset_signer(paytoken);
  //
  //     let bal = await paycon.balanceOf(vault);
  //     bal = parseFloat(tofeth(bal));
  //     if (bal < fee) throw new Error("insufficient balance");
  //     await cdelay(1 * 1e3);
  //
  //     set_respdata({ status: "loading", msg: "Checking Allowance..." });
  //     let alw = await paycon.allowance(vault, rcon.contractAddress);
  //     alw = parseFloat(tofeth(alw));
  //     if (alw < fee) {
  //       // throw new Error("insufficient allowance");
  //       set_respdata({ status: "info", msg: "insufficient allowance" });
  //       await cdelay(1 * 1e3);
  //       let amt = fee * 1.05;
  //       amt = parseFloat(dec(amt, tokdecn(paytoken)));
  //       console.log("allowance amt", amt);
  //
  //       if (aumode == "thirdweb") {
  //         set_respdata({ status: "success", msg: `Approving Allowance...` });
  //         let resp = await t3_contract_call(
  //           paytoken,
  //           "approve",
  //           [rcon.contractAddress, toeth(amt)],
  //           "txn",
  //           true,
  //           { active_account },
  //         );
  //       } else {
  //         let resp = await paycon.approve(rcon.contractAddress, toeth(amt));
  //         set_respdata({ status: "success", msg: `Approving Allowance...` });
  //         resp = await resp.wait();
  //         set_respdata({ status: "success", msg: `ALlowance Approved` });
  //       }
  //     }
  //     await cdelay(1 * 1e3);
  //
  //     set_respdata({ status: "loading", msg: "Entering Quest..." });
  //     let hid = getv(data, "hid");
  //     console.log("entering race", { rid, hid });
  //     let resp = null;
  //     if (aumode == "thirdweb") {
  //       let racekey = get_racestake_key({ version: r.version });
  //       console.log("racekey", racekey);
  //       resp = await t3_contract_call(
  //         racekey,
  //         "enterRace",
  //         [rid, hid],
  //         "txn",
  //         true,
  //         { active_account },
  //       );
  //     } else {
  //       resp = await rcon.enterRace(rid, hid);
  //       resp = await resp.wait();
  //     }
  //
  //     if (post_enterrace)
  //       await post_enterrace({ rid, hid, quest_type: data.quest_type });
  //
  //     // await roadmap_post_enter_race();
  //
  //     let mark = await q_quest_mark_enter({
  //       rid,
  //       hid,
  //       vault,
  //     }).queryFn();
  //     console.log("mark enter", mark);
  //     set_respdata({ status: "success", msg: `Txn Sent` });
  //
  //     await q_logtxnqueue({
  //       data: {
  //         type: "after_cont",
  //         rid,
  //         hid,
  //         vault,
  //         time: iso(),
  //         hash: resp.hash,
  //       },
  //     }).queryFn();
  //     if (is_trial) {
  //       await cdelay(2 * 1e3);
  //       await refresh_roadmap();
  //     }
  //     history(`/race/${rid}`);
  //     // window.open(`/race/${rid}`, "_self");
  //     // window.open(polytxnlink(resp.hash));
  //     //
  //   } catch (err) {
  //     console.log(err);
  //
  //     let msg = !nils(err.reason) ? err.reason : err.message;
  //     if (msg.length > 100) msg = msg.substring(0, 100) + "...";
  //     set_respdata({ status: "error", msg });
  //
  //     setTimeout(() => {
  //       set_respdata({
  //         status: "action",
  //         msg: `Retry Paying Fee:${dec(r.fee, tokdecn[r.paytoken])} ${r.paytoken}`,
  //         action: () => pay_quest(r),
  //       });
  //     }, 4 * 1e3);
  //     return;
  //   }
  // };
  // const enterquest = async () => {
  //   try {
  //     let inp = {
  //       hid: getv(data, "hid"),
  //       cb: getv(data, "cb"),
  //       feeusd: getv(data, "feeusd"),
  //       quest_type: getv(data, "quest_type"),
  //       rvmode: getv(data, "rvmode"),
  //     };
  //     if (!nils(entry_ticket)) {
  //       inp.entry_ticket = entry_ticket;
  //     }
  //     let roadmap_inf = await get_roadmap_status(vault);
  //     let currtaskid = getv(roadmap_inf, "currtask.id");
  //     if (!nils(currtaskid) && currtaskid.includes("quest")) {
  //       inp.currtaskid = currtaskid;
  //     }
  //
  //     if (!nils(override_info?.k)) {
  //       inp.override_k = override_info.k;
  //     }
  //     set_respdata({ status: "loading", msg: "Creating Quest..." });
  //     let test = false;
  //     let resp = !test ? await q_quest_create(inp).queryFn() : {};
  //     console.log("enterquest resp", resp);
  //
  //     if (!nils(resp.err)) {
  //       throw new Error(`Error: ${resp.err}`);
  //     }
  //
  //     let rid = getv(resp, "result.rid");
  //     if (nils(rid)) throw new Error("Quest not created");
  //     set_respdata({ status: "success", msg: `Quest Created` });
  //     await cdelay(2 * 1e3);
  //     let r = getv(resp, "result");
  //
  //     if (r.need_topay === false) {
  //       let rid = getv(r, "rid");
  //       set_respdata({
  //         status: "success",
  //         msg: `taking you to race`,
  //       });
  //       await cdelay(2 * 1e3);
  //
  //       if (is_trial) {
  //         await refresh_roadmap();
  //       }
  //       // window.open(`/race/${rid}`, "_self");
  //       history(`/race/${rid}`);
  //     } else {
  //       set_respdata({
  //         status: "success",
  //         msg: `need to pay fee: ${dec(r.fee, tokdecn[r.paytoken])} ${r.paytoken}`,
  //       });
  //       await cdelay(2 * 1e3);
  //       await pay_quest(r);
  //     }
  //   } catch (err) {
  //     let msg = !nils(err.reason) ? err.reason : err.message;
  //     if (msg.length > 100) msg = msg.substring(0, 100) + "...";
  //     set_respdata({ status: "error", msg });
  //
  //     if (err.message == "Quest not created") {
  //       setTimeout(() => {
  //         set_respdata({
  //           status: "action",
  //           action: enterquest,
  //           msg: "Retry Entering Quest",
  //         });
  //       }, 2 * 1e3);
  //     }
  //   }
  // };

  const selhids_searchtxt = extract_inp("autofiller-hid-search");
  const validhids = useMemo(() => {
    let hids = _.map(hs, "hid");
    // console.log("validhids", hids);
    return hids;
  }, [jstr(hs)]);
  const filthids = useMemo(() => {
    let searchtxt = selhids_searchtxt;
    if (nils(searchtxt)) return validhids;
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(validhids)
      .map((hid) => {
        let h = bikesob[hid];
        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [hid, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [hid, 2];
        else if (hname.startsWith(sear)) return [hid, 3];
        else if (hname.includes(sear)) return [hid, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .map(0)
      .value();
    return filt;
  }, [jstr(hids), jstr(validhids), selhids_searchtxt]);

  const pre_pay_loaded =
    !nils(vault) && auth === true && !nils(aumode) && !_.isEmpty(appcon.tokmap);

  const lb_ref = useRef(null);
  const start_quest = async () => {
    if (!pre_pay_loaded) return;
    if (logbox.status == "loading") return;
    cancelref.current = null;

    await cdelay(0.5 * 1e3);

    let token = "DEZ";
    let { feeusd: amt_usd } = data;
    let lb_params = {
      vault,
      token,
      aumode,
      logbox,
      set_logbox,
      appcon,
      t3con,
    };

    if (cancelref.current) return;
    // console.log("lb_params", lb_params);
    let lb = new LogBox(lb_params, { update_log: true, wait: true });
    lb_ref.current = lb;
    await lb.init();

    let alw_to = contractAddress_list["cv2_dnapayrouter"];
    let amt_tok = lb.usd_to_tok(amt_usd);

    if (cancelref.current) return;
    let { done, err } = await lb.tok_bal_alw_apv([alw_to, amt_tok]);
    if (!nils(err)) return;

    await cdelay(2 * 1e3);
    console.log("lb.amt", amt_tok, lb.amt_tok);

    let service = "QuestRaceV03";

    let con_class = DNA_PayReceiver;
    // let con_initparams = { aumode, active_account: t3con.active_account };
    let encoded = {
      feeusd: data.feeusd,
      hid: getv(data, "hid"),
      rvmode: getv(data, "rvmode"),
      quest_type: getv(data, "quest_type"),
      overide_k: getv(data, "overide_k"),
      cb: getv(data, "cb"),
      date: iso(),
    };
    let args = [service, toeth(lb.amt_tok), token, json_to_base64(encoded)];
    let txinfo = {};
    let msgs = {
      action: `Confirm Payment Transaction for ${service}`,
      loading: `Processing Payment to ${service}`,
      on_success: (tx) => {
        lb.edit_last_step([
          "success",
          <div class="fr-sc w-full resp-gap-1 text-green-400">
            <TokenIcon token="POL" size="md" />
            <span>Transaction Sent: {trim2(tx.hash, 6, 6)}</span>
          </div>,
          {},
          polytxnidlink(tx.hash),
        ]);
      },
    };

    if (cancelref.current) return;
    let tx = await lb.send_cv2_tx([
      con_class,
      "send_payment",
      args,
      txinfo,
      msgs,
    ]);
    if (cancelref.current) return;
    console.log("tx", tx);

    if (!nils(tx.hash)) {
      await q_que_txn({
        service: "QuestRaceV03",
        hash: tx.hash,
        dets: { servicename: service },
      }).queryFn();
    }

    await cdelay(2 * 1e3);
    console.log("lb.amt", amt_tok, lb.amt_tok);
    if (nils(tx.hash) || tx.err) {
      return;
    }

    let payid = getv(tx.parsedlogs, "0.args.payid");
    lb.add_step([
      "success",
      <div class="fc-ss w-full resp-gap-1 text-green-400">
        <p>PayId: {trim2(payid, 6, 6)}</p>
      </div>,
      {},
      polytxnidlink(tx.hash),
    ]);
    await cdelay(2 * 1e3);
    let rid = `quest_${payid}`;

    if (post_enterrace) {
      await post_enterrace({
        rid,
        hid: getv(data, "hid"),
        quest_type: data.quest_type,
      });
    }

    set_data({ ...data, rid });
    set_stage(7);
  };

  const retry_pay = async (r) => {
    set_logbox({});
    await cdelay(1 * 1e3);
    start_quest();
  };

  return (
    <div>
      <p className={headcn}>{"Enter a Quest"}</p>
      <Card className={twMerge("xs:w-[95vw] lg:w-[60rem]  p-4", card_cn)}>
        <div class="p-2 w-full h-[30rem] fc-sc">
          {/*           <p class="resp-text--1 w-full">{jstr(data)}</p> */}
          <div class="w-full fr-sc gap-1">
            {!nils(data.rvmode) && (
              <>
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <span className="resp-text--1 font-digi ">
                    Mode: {data.rvmode}
                  </span>
                </Tag>
              </>
            )}
            {!nils(data.cb) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <span className="resp-text--1 font-digi ">
                    {!nils(custom_txt_fns.cb)
                      ? custom_txt_fns.cb(data.cb, data.rvmode)
                      : cb_txt(data.cb, data.rvmode)}
                  </span>
                </Tag>
              </>
            )}
            {!nils(data.feeusd) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <span className="resp-text--1 font-digi ">
                    {dec(data.feeusd, 2)}
                  </span>
                  <span className="resp-text--1 font-digi">USD</span>
                </Tag>
              </>
            )}

            {!nils(data.hid) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <p className={"text-white resp-text--1"}>
                    {`Core: #${data.hid} ${getv(data, "h.name")}`}
                  </p>
                </Tag>
              </>
            )}

            {!nils(data.quest_type) && (
              <>
                <FontAwesomeIcon className="resp-text-1" icon={faArrowRight} />
                <Tag className="text-white bg-acc4/20 -skew-x-12 resp-px-2 fr-sc">
                  <p className={"text-white resp-text--1"}>{data.quest_type}</p>
                </Tag>
              </>
            )}
            <div class="flex-1"></div>
            <Tag
              onClick={() => {
                reset_fn();
              }}
              className="fr-sc resp-gap-1 text-acc4 border border-acc4"
            >
              <FontAwesomeIcon icon={faUndoAlt} />
              <span className="resp-text--1">Reset</span>
            </Tag>
          </div>

          {stage == 0 ? (
            <>
              <p className={headcn}>Choose Racing Vehicle Mode</p>
              <div class="fr-cc w-full flex-wrap gap-4">
                {["car", "bike", "horse"].map((rvmode) => {
                  return (
                    <div
                      onClick={() => {
                        set_data((o) => ({ ...o, rvmode }));
                        go_next();
                      }}
                      class={twMerge("cursor-pointer xs:w-[8rem] lg:w-[16rem]")}
                    >
                      <SkewWrap2>
                        <span className={twMerge("resp-text-2 font-digi")}>
                          {_.capitalize(rvmode)}
                        </span>
                      </SkewWrap2>
                    </div>
                  );
                })}
              </div>
            </>
          ) : stage == 1 ? (
            <>
              <p className={headcn}>Choose a Distance</p>
              <div class="fr-cc w-full flex-wrap gap-4">
                {(data.rvmode == "bike" ? [10, 14, 18, 22] : season_cbs).map(
                  (cb) => {
                    const [btn_cn, text_cn] = get_active_cn("cb", cb);
                    return (
                      <div
                        onClick={() => {
                          set_data((o) => ({ ...o, cb }));
                          go_next();
                        }}
                        class={twMerge(
                          "cursor-pointer xs:w-[8rem] lg:w-[16rem]",
                          btn_cn,
                        )}
                      >
                        <SkewWrap2>
                          <span
                            className={twMerge(
                              "resp-text-2 font-digi",
                              text_cn,
                            )}
                          >
                            {!nils(custom_txt_fns.cb)
                              ? custom_txt_fns.cb(cb, data.rvmode)
                              : cb_txt(cb, data.rvmode)}
                          </span>
                        </SkewWrap2>
                      </div>
                    );
                  },
                )}
              </div>
            </>
          ) : stage == 2 ? (
            <>
              <p className={headcn}>Choose Fee Amt</p>
              <div class="grid grid-cols-2 gap-4">
                {(is_trial ? [1] : allowed_fees).map((op) => {
                  const [btn_cn, text_cn] = get_active_cn("feeusd", op);
                  return (
                    <div
                      onClick={() => {
                        set_data((o) => ({ ...o, feeusd: op }));
                        go_next();
                      }}
                      class={twMerge(
                        "cursor-pointer xs:w-[8rem] lg:w-[16rem]",
                        btn_cn,
                      )}
                    >
                      <SkewWrap2>
                        <div
                          className={twMerge(
                            " fr-sc resp-text-2 font-digi",
                            text_cn,
                          )}
                        >
                          <FontAwesomeIcon icon={faUsd} />
                          <span>{dec(op, 2)}</span>
                        </div>
                      </SkewWrap2>
                    </div>
                  );
                })}
              </div>
            </>
          ) : stage == 5 ? (
            <>
              <div class="fc-cc h-full gap-2 font-digi resp-text-1">
                {override_info?.k == "trainer" ? (
                  <p className={" text-center"}>
                    Earn points in each round of your quest to climb the
                    tournament leaderboard.
                    <br />
                    Complete your quest by finishing
                    <span className="text-acc4">{` top 3 in all 3 rounds `}</span>
                    to earn
                    <span className="text-acc4">{` bonus points.`}</span>
                  </p>
                ) : (
                  <>
                    <p className={"mx-auto xs:w-full lg:w-[60%]"}>
                      <span>{`If you complete this quest you will get `}</span>
                      <span className="text-acc4">${gen_payout(data.fee)}</span>
                    </p>

                    {data.quest_type == "progressive" ? (
                      <p className="mx-auto xs:w-full lg:w-[60%]">
                        <span class="">
                          <span className="text-acc4">Finish Top3</span>
                          {` to win each round`}
                        </span>
                        <span>{` this is a progressive quest `}</span>
                        <span>
                          <span>{`after each winning round you will have a `}</span>
                          <span className="text-acc4">
                            {` choice to cash out or continue on to win more! `}
                          </span>
                        </span>
                      </p>
                    ) : data.quest_type == "3-rounds" ? (
                      <p class="">
                        <span className="text-acc4">Finish Top3</span>
                        <span>{` in 3 Rounds to complete the quest`}</span>
                      </p>
                    ) : null}
                  </>
                )}
                {_.isEmpty(respdata) ? (
                  <Tag
                    onClick={async () => {
                      set_stage(6);
                      await cdelay(0.5 * 1e3);
                      // enterquest();
                      start_quest();
                    }}
                    className={"bg-acc4/40 -skew-x-12"}
                  >
                    <p class="resp-text--1">Start Quest Now</p>
                  </Tag>
                ) : respdata.status == "action" ? (
                  <Tag
                    onClick={() => {
                      let action = respdata?.action;
                      if (action) action();
                    }}
                    className={"bg-orange-400/40 -skew-x-12"}
                  >
                    <p class="resp-text--1">{respdata?.msg}</p>
                  </Tag>
                ) : ["loading", "info", "success", "error"].includes(
                    respdata?.status,
                  ) ? (
                  <div
                    class={twMerge(
                      "fr-sc p-2 rounded-md border max-w-full w-[30rem] border",
                      respdata?.status == "error"
                        ? "border-red-400 text-red-400 "
                        : respdata?.status == "info" ||
                            respdata?.status == "loading"
                          ? "border-acc4 text-acc4 "
                          : respdata?.status == "success"
                            ? "border-green-400 text-green-400 "
                            : "",
                    )}
                  >
                    {respdata?.status == "loading" && <Loader01c size="s" />}
                    <p>{respdata?.msg}</p>
                  </div>
                ) : null}
              </div>
            </>
          ) : stage == 3 ? (
            <>
              <div class="h-full relative w-full overflow-auto">
                <div class="fr-sc h-[4rem] w-full gap-2">
                  <p className="font-digi resp-text-2 flex-1">
                    Select A {_.capitalize(data.rvmode)}
                  </p>

                  <StatType_Switches
                    {...{
                      st_type,
                      set_st_type,
                      st_paid,
                      set_st_paid,
                      cb: data.cb,
                    }}
                  />
                </div>

                {is_trial == true && !_.isEmpty(rem_tickets) && (
                  <>
                    <div class="fr-sc resp-gap-1">
                      <div class="flex-1"></div>
                      <Tag
                        onClick={() => {
                          if (entry_ticket) {
                            set_entry_ticket(null);
                          } else {
                            let rem = _.shuffle(rem_tickets)[0];
                            set_entry_ticket(rem);
                          }
                        }}
                        className="fr-sc resp-gap-2"
                      >
                        {!nils(entry_ticket) ? (
                          <FontAwesomeIcon
                            className="resp-text-0 text-green-400"
                            icon={faCheck}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="resp-text-0 text-slate-400"
                            icon={faSquare}
                          />
                        )}
                        <span class="resp-text-0 font-digi">
                          {`Use Ticket for Free Entry `}
                          <span className="text-acc0">
                            [remaining: {rem_tickets.length}]
                          </span>
                        </span>
                      </Tag>
                    </div>
                  </>
                )}

                <div class="my-2 xs:w-[95%] w-[90%] mx-auto">
                  <InpText
                    {...{
                      id: "autofiller-hid-search",
                      setter: () => {},
                      contprops: {
                        className: "w-full bg-r2dark",
                      },
                      inpprops: {
                        className: "w-full",
                      },
                      placeholder: "Search by coreid or Name",
                      autoComplete: "off",
                    }}
                  />
                </div>

                <div
                  style={{
                    height: "calc(100% - 4rem)",
                  }}
                  className="fc-ss overflow-auto  w-full"
                >
                  {filthids.map((hid) => {
                    let h = getv(hsob, hid);
                    if (nils(h)) return <></>;

                    // let { hid } = h;
                    // console.log(h);
                    if (!is_trial && h.type == "trainer") return <></>;
                    if (nils(h.name) || h.name === "Unnamed DNA Splice")
                      return <></>;

                    const selected = data?.hid == hid;

                    const entertbtn = (
                      <Tag
                        onClick={() => {
                          set_data((o) => ({ ...o, hid, h }));
                          setTimeout(() => {
                            go_next();
                          }, 0.5 * 1e3);
                        }}
                        className={twMerge(
                          "bike-enter-btn",
                          "resp-px-4 fr-cc resp-gap-2",
                          "bg-r2reg text-white",
                          selected ? "bg-r2dark text-acc4" : "",
                        )}
                      >
                        <span>
                          {selected
                            ? "Selected"
                            : `Select ${_.capitalize(data?.rvmode ?? "bike")}`}
                        </span>
                      </Tag>
                    );

                    return (
                      <div
                        class={twMerge(
                          "w-full cursor-pointer rounded-md resp-p-1 resp-my-1",
                          selected ? "bg-acc4/30 border border-acc4" : "",
                        )}
                      >
                        <RaceR2_HRow
                          {...{
                            r: {
                              rvmode: data.rvmode,
                            },
                            hid,
                            h,
                            st_type,
                            st_paid,
                            // right_flex_jsx: entertbtn,
                            cont_props: {
                              className: "bg-tranparent resp-text--2",
                              style: {
                                borderLeft: undefined,
                                borderRight: undefined,
                              },
                              onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                set_data((o) => ({
                                  ...o,
                                  hid,
                                  h,
                                }));
                                go_next();
                              },
                            },
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : stage == 4 ? (
            <div class="h-full relative w-full overflow-auto">
              <div class="fr-cc h-[4rem] w-full gap-2">
                <p className="font-digi resp-text-2 text-center flex-1">
                  Select Quest Type
                </p>
              </div>
              <div class="fr-cc w-full gap-2">
                {[
                  "3-rounds",
                  ...(override_info?.k !== "trainer" ? ["progressive"] : []),
                ].map((op) => {
                  const k = "quest_type";
                  const [btn_cn, text_cn] = get_active_cn(k, op);
                  return (
                    <div
                      onClick={() => {
                        set_data((o) => ({ ...o, [k]: op }));
                        go_next();
                      }}
                      class={twMerge(
                        "cursor-pointer xs:w-[8rem] lg:w-[16rem]",
                        btn_cn,
                      )}
                    >
                      <SkewWrap2>
                        <span
                          className={twMerge("resp-text-2 font-digi", text_cn)}
                        >
                          {op}
                        </span>
                      </SkewWrap2>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : stage == 6 ? (
            <>
              <div class="fc-sc w-full">
                <p className={headcn}>Processing Payment</p>
                {/*               <div class="fc-cc"> <p>{jstr(logbox)}</p> </div> */}
                <div class="w-full max-w-[50rem] mx-auto resp-px-2 my-1 max-h-[30vh] overflow-auto">
                  <div class="h-full">
                    {getv(logbox, "steps", []).map((step) => {
                      return (
                        <div class="w-full font-digi my-2">
                          {lb_ref.current.render_step_row(step)}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div class="fr-sc my-1">
                  <div class=""></div>
                  {_.isEmpty(logbox) ? null : logbox?.status == "error" ? (
                    <Tag
                      onClick={() => {
                        if (logbox.status == "loading") return;
                        retry_pay();
                      }}
                      className={twMerge("bg-red-500 -skew-x-12")}
                    >
                      <span className="resp-text-1 font-digi ">
                        {"Retry Payment"}
                      </span>
                    </Tag>
                  ) : logbox?.status == "loading" ? (
                    <FontAwesomeIcon
                      className={twMerge("resp-text-2 text-acc4 spin-anim")}
                      icon={faSpinner}
                    />
                  ) : null}
                </div>
              </div>
            </>
          ) : stage == 7 ? (
            <WaitForRaceGeneration
              {...{
                data,
                rid: getv(data, "rid"),
              }}
            />
          ) : null}
        </div>
        <div class="cursor-pointer fr-sc w-full gap-2">
          <div
            onClick={() => {
              go_prev();
            }}
            class="cursor-pointer fr-sc rounded-md bg-r2dark/40 px-[2rem] py-[0.5rem]"
          >
            <FontAwesomeIcon
              className="xs:text-[1rem] lg:text-[3rem]"
              icon={faArrowLeftLong}
            />
          </div>

          <div class="flex-1"></div>
          {[...new Array(mxstage)].map((e, i) => {
            return (
              <div
                class={twMerge(
                  "xs:w-[0.5rem] lg:w-[1rem] aspect-[1/1] rounded-full bg-white",
                  i < stage ? "" : "opacity-20",
                )}
              ></div>
            );
          })}
          <div class="flex-1"></div>

          <div
            onClick={() => {
              if (!validate_next) return;
              go_next();
            }}
            class={twMerge(
              "cursor-pointer fr-sc rounded-md bg-r2dark/40 px-[2rem] py-[0.5rem]",
              validate_next ? "" : "opacity-0",
            )}
          >
            <FontAwesomeIcon
              className="xs:text-[1rem] lg:text-[3rem]"
              icon={faArrowRightLong}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

const WaitForRaceGeneration = ({ data, rid }) => {
  const { now } = useNowContext();
  const history = useNavigate();
  const [qorace] = useQueries([
    q_race(
      { rid },
      {
        staleTime: 5 * 1e3,
        refetchInterval: 5 * 1e3,
      },
    ),
  ]);
  const race = useMemo(() => {
    let d = getv(qorace, "data.result");
    return d;
  }, [qorace.dataUpdatedAt]);

  const init_time = useMemo(() => {
    return now;
  }, []);

  const waitlim = 2 * 60 * 1e3;
  const waitexcceed = now - init_time > waitlim;

  const race_status = useMemo(() => {
    if (!_.isEmpty(race)) {
      setTimeout(() => {
        history(`/race/${rid}`);
      }, 1 * 1e3);
      return "success";
    } else if (waitexcceed) return "error";
    else return "loading";
  }, [jstr(race), waitexcceed]);

  return (
    <>
      <div class="resp-text-1 font-digi fc-cc h-full w-full resp-gap-1">
        <p class="rsp-text-2 text-acc4 my-2">Generating Race...</p>
        {race_status == "loading" ? (
          <FontAwesomeIcon
            className="text-acc4 spin-anim resp-text-2"
            icon={faSpinner}
          />
        ) : race_status == "success" ? (
          <p class="fr-sc resp-gap-1">
            <FontAwesomeIcon className="text-green-400" icon={faCheckCircle} />
            <span>Race generated</span>
          </p>
        ) : race_status == "error" ? (
          <>
            <p class="">Not Sure if your race was generated</p>
          </>
        ) : null}

        <div class="h-[1rem]"></div>
        <p className="resp-text--1">
          To create a new quest, please reset this window{" "}
        </p>
        <p className="resp-text--1">Or....</p>
        <p className="resp-text--1">
          You can always check vault races section in main lobby
        </p>
        <div class="h-[5rem]"></div>
      </div>
    </>
  );
};

const QuestSelectPage = () => {
  return (
    <div class="h-page">
      <div class="xs:h-[2rem] lg:h-[4rem]"></div>
      <QuestSelect />
      <div class="h-[4rem]"></div>
    </div>
  );
};

export default QuestSelectPage;
