import React, {
  useEffect,
  useState,
  usetContext,
  createContext,
  useMemo,
  useContext,
  useRef,
} from "react";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { PageWrapper } from "../components/ShortComps";
import {
  InpText,
  PolyLink,
  SwitchTag,
  Tag,
  TokenIcon,
} from "../components/utilityComps";
import {
  cdelay,
  dec,
  getv,
  iso,
  iso_format,
  json_to_base64,
  jstr,
  nils,
  toeth,
  tofeth,
  trim2,
  trim_n,
} from "../utils/utils";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs";
import { useThirdWebLoginContext } from "./ThirdWebLogin";
import { contractAddress_list } from "../contracts/constants";
import { tokdecn, tokdecn2, useAppContext } from "../App";
import { DNA_PayReceiver } from "../contracts/v2/DNA_PayReceiver.js";
import { useQueries } from "react-query";
import {
  open_polylink,
  polytxnidlink,
  q_challenge_event,
  q_challenge_eventslist,
  q_challenge_ledger,
  q_que_txn,
  q_races,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { Loader01c } from "../components/anims.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faArrowRightLong,
  faCaretLeft,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faSpinner,
  faTriangleExclamation,
  faUndoAlt,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import _, { trim } from "lodash";
import { RaceR2_HRow, SkewWrap2 } from "../utils/raceutils2.js";
import { RVImg } from "../components/BikeImg.js";
import { twMerge } from "tailwind-merge";
import {
  elementmap,
  gendermap,
  get_payout_txt,
  tablecn,
} from "../utils/cn_map.js";
import { HashToColors, LogBox } from "../contracts/txns_logbox_helper.js";
import { Link, useParams } from "react-router-dom";
import { RacesList, RacesListInner, RacesListWrapped } from "./Races.js";
import { BackLink } from "./Vault.js";
import { RoundRace_Full } from "./RoundRaces.js";
import { match_cri_to_bikedata } from "../utils/filt.js";
import { faReddit } from "@fortawesome/free-brands-svg-icons";

const ChallengeContext = createContext({});
export const useChallengeContext = () => useContext(ChallengeContext);
export const ChallengePage = () => {
  const appcon = useAppContext();
  const aucon = useAuthContext();
  const accon = useAccountContext();
  const t3con = useThirdWebLoginContext();

  const { psearch, upd_psearch } = appcon;
  const { aumode, auth, vault } = aucon;
  const { hids: vhids, bikesob } = accon;

  const params = useParams();
  const id = params.id;

  const [qoevent] = useQueries([
    q_challenge_event(
      { id },
      { enabled: !nils(id), refetchInterval: 20 * 1e3, staleTime: 20 * 1e3 },
    ),
  ]);
  const event = useMemo(() => {
    let e = getv(qoevent, "data.result");
    return e;
  }, [qoevent.dataUpdatedAt]);

  const [active_fseg, set_active_fseg] = useState(psearch.active_fseg);
  const fee_segments = useMemo(() => {
    let fs = _.keys(getv(event, "fee_segs", {}));
    if (_.isEmpty(fs)) return [];
    if (active_fseg === undefined) {
      if (!nils(psearch.active_fseg) && fs.includes(psearch.active_fseg))
        set_active_fseg(psearch.active_fseg);
      else set_active_fseg(fs[0]);
    }
    return [...fs, "ledger"];
  }, [jstr(event), psearch.active_fseg]);

  useEffect(() => {
    if (active_fseg === undefined) return;
    upd_psearch({ active_fseg: active_fseg });
  }, [jstr(active_fseg)]);

  const [my, set_my] = useState(false);

  const ccon = {
    id,

    fee_segments,
    fee_txt_fn,

    active_fseg,
    set_active_fseg,

    qoevent,
    event,

    my,
    set_my,
  };

  return (
    <>
      <ChallengeContext.Provider value={ccon}>
        <PageWrapper page_title_fn={() => `${id} | DNA Challenge`}>
          <BackLink to={`/challenge`} msg="Challenges Home" />
          {!_.isEmpty(event) && (
            <>
              <p class="resp-text-2 font-digi my-2 text-center text-acc4">{`${event?.name}`}</p>
              <p class="resp-text-1 font-digi my-2 text-center">
                <span>{`DNA Challenge`}</span>
                <span>{" - "}</span>
                <span
                  className={twMerge(
                    "uppercase",
                    status_txt_cnmap[event?.status],
                  )}
                >
                  {event?.status}
                </span>
              </p>
            </>
          )}
          {qoevent.isLoading ? (
            <Loader01c />
          ) : qiserr(qoevent) ? (
            <p class="text-center text-red-300 resp-text-1 font-digi">
              {qiserr(qoevent)}
            </p>
          ) : qissuccesss(qoevent) ? (
            <div class="fc-cc w-full">
              <div class="grid grid-cols-6 max-w-full min-w-[30rem] resp-gap-4 py-2 border-t border-b border-acc4">
                {fee_segments.map((fee) => {
                  let [feetxt, cspan] = fee_txt_fn(fee);
                  return (
                    <div style={{}} class={`col-span-${cspan}`}>
                      <Tag
                        onClick={() => {
                          set_active_fseg(fee);
                        }}
                        className={twMerge(
                          "fr-cc w-full resp-gap-1 transition duration-300",
                          active_fseg == fee ? "bg-acc4/40" : "text-acc4",
                        )}
                      >
                        <span className="font-digi resp-text-1 ">{feetxt}</span>
                      </Tag>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          <div class="h-[2rem]"></div>
          {/*           <p class="resp-text-2 text-center">{active_fseg}</p> */}
          <div class="fr-sc">
            <p class="resp-text-2 font-digi text-center">{"Qualified"}</p>
            <div class="flex-1"></div>

            <SwitchTag
              {...{
                on: my,
                set_on: set_my,
                title: "My Vault",
              }}
            />
          </div>
          <UpgradeDowngadeTags {...{ fseg: active_fseg }} />
          {/* {active_fseg && jstr(getv(event, `fee_segs.${active_fseg}`))} */}
          {nils(active_fseg) ? null : active_fseg == "ledger" ? (
            <ChallengeLedgerSection {...{ vault }} />
          ) : active_fseg == "xchampion_leader" ? (
            <ChallengeXChampionLeader {...{ fseg: active_fseg }} />
          ) : active_fseg.endsWith("usd") ? (
            <ChallengeSubFeeSegment {...{ vault, fseg: active_fseg }} />
          ) : null}
        </PageWrapper>
      </ChallengeContext.Provider>
    </>
  );
};

const status_txt_cnmap = {
  yettostart: "text-yellow-400",
  active: "text-green-400",
  ended: "text-red-400",
};

const status_led_cnmap = {
  exit: "border-slate-400 text-slate-400",
  active: "border-green-400 text-green-400",
  invalid: "border-red-400 text-red-400",
  refunding_dust: "border-yellow-400 text-yellow-400",
};

const fee_txt_fn = (fee) => {
  if (nils(fee)) return ["--", 2];
  return fee.endsWith("usd")
    ? [`${fee.slice(0, -3).replace("_", ".")}`, 2]
    : fee == "xchampion_leader"
      ? ["Champ Finals", 3]
      : [_.capitalize(fee), 3];
};
const pure_fee_txt = (fee) => {
  let [a, b] = fee_txt_fn(fee);
  return a;
};

const ChallengeHidsGridView = ({ hids, f, ft }) => {
  const ccon = useChallengeContext();
  const { event } = ccon;
  const acccon = useAccountContext();
  const { hids: vhids } = acccon;

  return (
    <div class="grid grid-cols-3 gap-3">
      {hids.map((hid) => {
        let h = getv(event.hmap, hid, {});
        if (_.isEmpty(h)) return <span>{hid}</span>;

        let th = getv(ft, `track.${hid}`, {});
        let upgrade = f.upgrade;
        let downgrade = f.downgrade;
        let is_my = vhids.includes(hid);

        return (
          <div
            class={twMerge(
              "resp-p-2 bg-r2lig/20 font-digi resp-text--1 border border-acc4 rounded-lg",
              is_my ? "bg-acc4/40" : "",
            )}
          >
            <div class="fr-sc resp-text-0 resp-gap-2 my-2">
              <span class="text-acc4 resp-text--1">#{hid}</span>
              <span>-</span>
              <span>{trim_n(h.name, 15)}</span>
            </div>

            <div class="fr-sc resp-text--2 text-acc4 italic resp-gap-2 mb-2">
              <div class="flex-1"></div>
              <span>
                {trim_n(getv(event, `vmap.${event.hid_vault_map[hid]}`), 25)}
              </span>
            </div>
            <div class="fr-sc resp-gap-2 my-2">
              <span className="uppercase">{h.type.slice(0, 3)}</span>
              <span>F{h.fno}</span>
              <div class="flex-1"></div>
              <FontAwesomeIcon
                className={`resp-text-1 ${getv(elementmap, `${h.element}.text`)}`}
                icon={getv(elementmap, `${h.element}.icon`)}
              />
              <FontAwesomeIcon
                className={`resp-text-1 ${getv(gendermap, `${h.gender}.text`)}`}
                icon={getv(gendermap, `${h.gender}.icon`)}
              />
            </div>

            {!_.isEmpty(upgrade) && (
              <div class="fr-sc resp-gap-2 my-2 text-green-400">
                <span>upgrade:</span>
                <span>{dec(getv(th, "tuval", 0), 2)}</span>
                <span>/</span>
                <span>{upgrade.req}</span>
              </div>
            )}
            {!_.isEmpty(downgrade) && (
              <div class="fr-sc resp-gap-2 my-2 text-red-400">
                <span>downgrade:</span>
                <span>{dec(getv(th, "tdval", 0), 2)}</span>
                <span>/</span>
                <span>{downgrade.req}</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const upg_down_mode = {
  consec_lose_prize: (req) => `Lose Prize Money in consecutive ${req} races`,
  thresh_profit_usd: (req) => `Earn ${req} USD in segment races`,
  on_win: (req) => `Win ${req} ${req == "1" ? "race" : "races"}`,
  on_lose: (req) => `Lose ${req} ${req == "1" ? "race" : "races"}`,
  on_end: (req) => `When race ends`,
};

const UpgradeDowngadeTags = ({ fseg }) => {
  const ccon = useChallengeContext();
  const { event, vmap } = ccon;
  const [f] = useMemo(() => {
    if (nils(fseg)) return [null, null];
    let f = getv(event, `fee_segs.${fseg}`, {});
    return [f];
  }, [fseg, jstr(event)]);

  const cardcn = "border resp-p-4 rounded-md fc-cc";

  if (_.isEmpty(f)) return null;
  return (
    <>
      <div class="grid grid-cols-2 resp-gap-2">
        <div
          class={twMerge(
            cardcn,
            "bg-green-500/20 border-green-400 text-green-500",
            _.isEmpty(f.upgrade)
              ? "bg-slate-400/20 border-slate-400 text-slate-400"
              : "",
          )}
        >
          {!_.isEmpty(f.upgrade) ? (
            <>
              <p class="resp-text-1 font-digi">Upgrade</p>
              {!nils(upg_down_mode[f.upgrade.mode]) ? (
                <p>{upg_down_mode[f.upgrade.mode](f.upgrade.req)}</p>
              ) : null}
              <p>
                <span>{"Step up to "}</span>
                <span class="resp-text-1 font-digi">
                  {pure_fee_txt(f.upgrade.to)}
                </span>
                <span>{"Segment"}</span>
              </p>
            </>
          ) : (
            <>
              <p class="resp-text-1 font-digi">Keep Racing</p>
            </>
          )}
        </div>

        <div
          class={twMerge(
            cardcn,
            "bg-red-500/20 border-red-400 text-red-500",
            _.isEmpty(f.downgrade)
              ? "bg-slate-400/20 border-slate-400 text-slate-400"
              : "",
          )}
        >
          {!_.isEmpty(f.downgrade) ? (
            <>
              <p class="resp-text-1 font-digi">Downgrade</p>
              {!nils(upg_down_mode[f.downgrade.mode]) ? (
                <p>{upg_down_mode[f.downgrade.mode](f.downgrade.req)}</p>
              ) : null}
              <p>
                <span>{"Step down to "}</span>
                <span class="resp-text-1 font-digi">
                  {pure_fee_txt(f.downgrade.to)}
                </span>
                <span>{"Segment"}</span>
              </p>
            </>
          ) : (
            <>
              <p class="resp-text-1 font-digi">Keep Racing</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const StagingSection = ({ fseg }) => {
  const ccon = useChallengeContext();
  const aucon = useAuthContext();
  const accon = useAccountContext();
  const { hids: vhids } = accon;

  const { event, vmap, my } = ccon;

  const [f, ft] = useMemo(() => {
    if (nils(fseg)) return [null, null];
    let f = getv(event, `fee_segs.${fseg}`, {});
    let ft = getv(event, `fee_segs_track.${fseg}`, {});
    // console.log(fseg, f, ft, ft.staging_hids);
    return [f, ft];
  }, [jstr(event), fseg]);

  const show_hids = useMemo(() => {
    let hids = ft.staging_hids || [];
    if (my) return _.intersection(hids, vhids);
    return hids;
  }, [jstr(ft), my, jstr(vhids)]);

  if (nils(fseg) || nils(f)) return null;

  const rgate = getv(f, "rgate", null);
  const needed = Math.max(0, rgate - (ft.staging_hids || []).length);

  return (
    <div class="w-full">
      <div class="fr-sc resp-gap-2 resp-text-1 font-digi my-2 text-left w-full">
        <div class="flex-1">
          Staging Area - [{(ft.staging_hids || []).length}] Cores
        </div>
        {needed > 0 && (
          <div class="text-yellow-400 tex-text--1">
            need {needed} more core{needed > 1 && "s"}
          </div>
        )}
      </div>
      {!_.isEmpty(ft.staging_hids) && (
        <ChallengeHidsGridView {...{ hids: show_hids, f, ft }} />
      )}
    </div>
  );
};
const RunningSection = ({ fseg }) => {
  const ccon = useChallengeContext();
  const aucon = useAuthContext();
  const accon = useAccountContext();
  const { hids: vhids } = accon;
  const { event, my } = ccon;

  const [f, ft, rids] = useMemo(() => {
    if (nils(fseg)) return [null, null, []];
    let f = getv(event, `fee_segs.${fseg}`, {});
    let ft = getv(event, `fee_segs_track.${fseg}`, {});
    let rids = _.map(ft.curr_rs, "rid");
    return [f, ft, rids];
  }, [jstr(event), fseg]);

  const show_hids = useMemo(() => {
    let hids = ft.racing_hids || [];
    if (my) return _.intersection(hids, vhids);
    return hids;
  }, [jstr(ft), my, jstr(vhids)]);

  return (
    <div class="w-full">
      <div class="resp-text-1 font-digi my-2 text-left w-full">
        Racing - [{(ft.racing_hids || []).length}] Cores
      </div>
      {!_.isEmpty(ft.racing_hids) && (
        <ChallengeHidsGridView {...{ hids: show_hids, f, ft }} />
      )}
      <div class="h-[2rem]"></div>

      <div class="resp-text-1 font-digi my-2 text-left w-full">
        Currenty Running Races
      </div>

      <div className="block">
        <RacesListWrapped {...{ rids }} />
      </div>
    </div>
  );
};

const ChallengeSubFeeSegment = ({ fseg }) => {
  const ccon = useChallengeContext();
  // useEffect(() => { console.log("fseg", fseg); }, [fseg]);
  return (
    <>
      <div class="fc-cc">
        <StagingSection {...{ fseg }} />
        <div class="h-[2rem]"></div>

        <RunningSection {...{ fseg }} />
      </div>
    </>
  );
};

const ChallengeXChampionLeader = ({ fseg }) => {
  const ccon = useChallengeContext();
  const { event } = ccon;

  const [f, ft] = useMemo(() => {
    let feek = "xchampion_leader";
    let f = getv(event, `fee_segs.${feek}`, null);
    let ft = getv(event, `fee_segs_track.${feek}`, null);
    console.log("x", feek, f, ft);
    return [f, ft];
  }, [jstr(event)]);

  if (nils(f)) return null;

  return (
    <>
      <div class="fc-cc">
        <StagingSection {...{ fseg }} />
        <div class="h-[1rem]"></div>
        <hr />
        <div class="h-[1rem]"></div>

        {_.isEmpty(ft.curr_rs) ? (
          <div class="fr-cc resp-gap-2 resp-text-2 font-digi">
            <p>Leaderboard Pot :</p>
            <span>{dec(getv(ft, "pot.amt", 0), 0)}</span>
            <div class="w-max">
              <TokenIcon token={getv(ft, "pot.token")} size="md" />
            </div>
          </div>
        ) : (
          <>
            {f.format == "normal" ? (
              <>
                <div className="block">
                  <RacesListWrapped {...{ rids: _.map(ft.curr_rs, "rid") }} />
                </div>
              </>
            ) : f.format == "rounds" ? (
              <>
                {ft.curr_rs.map((r0) => {
                  return (
                    <RoundRace_Full
                      {...{
                        key: r0.rid,
                        rid: r0.rid,
                      }}
                    />
                  );
                })}
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

const ChallengeLedgerSection = ({ vault: auvault }) => {
  const ccon = useChallengeContext();
  const { id, event } = ccon;

  const appcon = useAppContext();
  const { psearch } = appcon;
  const vault = useMemo(() => {
    if (!nils(psearch.v)) return psearch.v.toLowerCase();
    else return auvault;
  }, [auvault, psearch.v]);

  const [qoledger] = useQueries([
    q_challenge_ledger(
      { vault, challenge_id: id },
      {
        enabled: !nils(vault) && !nils(id),
        refetchInterval: 30 * 1e3,
      },
    ),
  ]);
  const ledger = useMemo(() => {
    let ledger = getv(qoledger, "data.result", []);
    return ledger;
  }, [qoledger.dataUpdatedAt]);

  return (
    <>
      <div class="w-full">
        <div class="resp-text-1 font-digi my-2 text-left w-full">
          Vault Ledger: {vault}
        </div>
        {ledger.map((led) => {
          return (
            <LedgerRow
              {...{ leddoc: led, expanded: true, name_link_redirect: false }}
            />
          );
        })}
      </div>
    </>
  );
};

const ChallengesMainContext = createContext({});
export const useChallengesMainContext = () => useContext(ChallengesMainContext);
export const ChallengesPage = () => {
  const appcon = useAppContext();
  const aucon = useAuthContext();
  const t3con = useThirdWebLoginContext();

  const { psearch } = appcon;
  const { aumode, auth, vault: auvault } = aucon;
  const { tok_to_usd_val, usd_to_tok_val } = appcon;

  const service = "DNA_Challenge";
  const [logbox, set_logbox] = useState("");
  const [resp, set_resp] = useState({});

  const vault = useMemo(() => {
    let vault = auvault;
    if (!nils(psearch.v)) vault = psearch.v.toLowerCase();
    return vault;
  }, [psearch.v, auvault]);

  const [actbottom, set_actbottom] = useState("My Challenges");

  const [qo_eventslist] = useQueries([
    q_challenge_eventslist({}, { enabled: true, staleTime: 60 * 1e3 }),
  ]);
  const events = useMemo(() => {
    let events = getv(qo_eventslist, "data.result", []);
    return events;
  }, [jstr(qo_eventslist.dataUpdatedAt)]);

  const ccon = {};
  return (
    <PageWrapper page_title_fn={() => "DNA Challenge"}>
      <ChallengesMainContext.Provider value={ccon}>
        <p class="my-2 resp-text-3 text-center font-digi text-acc4">
          DNA Challenge
        </p>
        <EnterChallengeSection />
        <div class="fr-sc my-2">
          <div class="flex-1"></div>
          <Link
            to="https://www.reddit.com/r/DNARacing/comments/1ikos66/core_challenges_are_here/"
            class=" fr-sc resp-gap-1 text-acc4 font-digi resp-text-1"
          >
            <FontAwesomeIcon icon={faReddit} />
            <span>Help Tutorial</span>
          </Link>
        </div>
        <div class="h-[1rem]"></div>
        <hr className="my-2 bg-white/20 w-full" />
        <div class="fr-cc resp-gap-2">
          {["Active List", "My Challenges", "Ledger"].map((e, i) => {
            let active = actbottom == e;
            return (
              <Tag
                key={e}
                onClick={() => {
                  set_actbottom(e);
                }}
                className={twMerge(
                  "text-white resp-px-2 fr-sc transition duration-300 ",
                  active ? "-skew-x-12 bg-acc4/40 text-white" : "text-acc4",
                )}
              >
                <span className="resp-text-1 font-digi ">{e}</span>
              </Tag>
            );
          })}
        </div>
        <div class="fr-sc w-full">
          {actbottom == "Active List" ? (
            <>
              <div class="w-full">
                {events.map((e) => {
                  return (
                    <Link to={`/challenge/${e.id}`} className="w-full">
                      <div class="w-full resp-p-4 resp-text-1 font-digi my-3 border border-acc4 bg-acc4/20 backdrop-blur-md rounded-md fr-sc resp-gap-1">
                        <p>{e.name}</p>

                        <div class="flex-1"></div>

                        {!_.isEmpty(e.fee_segs_fills) &&
                          _.chain(e.fee_segs_fills)
                            .entries()
                            .map(([k, v]) => {
                              if (k == "xchampion_leader") {
                                let pot = getv(e, "xpot", {
                                  amt: 0,
                                  token: "DEZ",
                                });
                                return (
                                  <div class="fr-sc resp-text--1 resp-gap-1 w-[8rem] text-right">
                                    <div class="flex-1"></div>
                                    <p>{dec(pot.amt, tokdecn2(pot.token))}</p>
                                    <TokenIcon token={pot.token} />
                                  </div>
                                );
                              }
                              return (
                                <div class="w-[2rem] text-center">
                                  <span>{v.staging_hids_n}</span>
                                </div>
                              );
                            })
                            .value()}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </>
          ) : actbottom == "My Challenges" ? (
            <>
              {!nils(vault) && auth === true && (
                <VaultLedgerSection
                  {...{ vault, ext_q: { status: ["active"] } }}
                />
              )}
            </>
          ) : actbottom == "Ledger" ? (
            <>
              {!nils(vault) && auth === true && (
                <VaultLedgerSection {...{ vault }} />
              )}
            </>
          ) : null}
        </div>
      </ChallengesMainContext.Provider>
    </PageWrapper>
  );
};

const EnterChallengeSection = () => {
  const t3con = useThirdWebLoginContext();
  const aucon = useAuthContext();
  const accon = useAccountContext();
  const appcon = useAppContext();

  const service = "DNA_Challenge";
  const { tok_to_usd_val, usd_to_tok_val } = appcon;
  const { aumode, vault, auth } = aucon;
  const { hids: vault_hids, bikesob } = accon;

  const vhids = useMemo(() => {
    let maiden = [];
    for (let hid of vault_hids) {
      let h = bikesob[hid];
      if (!h) continue;
      if (h.is_maiden) maiden.push(hid);
    }
    return maiden;
  }, [vault_hids]);

  const def_data = {};
  // const def_data = { stage: 6, hid: 9444, cb: 10, payout: "DU", segment: "F1-50", id: "10|DU|F1-50", amt: "10.00", amt: "0.01", confirm_amt: true, };

  const [data, set_data] = useState(def_data);
  const [logbox, set_logbox] = useState({});
  const lb_ref = useRef(null);
  const stage = getv(data, "stage", 0);
  const cancelref = useRef(null);

  const start_fn = () => {
    set_data({ stage: 1 });
    set_logbox({});
    lb_ref.current = null;
    cancelref.current = null;
  };
  const reset_fn = () => {
    set_data({ stage: 0 });
    set_logbox({});
    lb_ref.current = null;
    cancelref.current = true;
  };

  const [qo_eventslist] = useQueries([
    q_challenge_eventslist(
      {
        hid: data.hid,
      },
      { enabled: !nils(vault) && !nils(data.hid) },
    ),
  ]);

  const [searchtxt, set_searchtxt] = useState(null);
  const filthids = useMemo(() => {
    if (nils(searchtxt)) return vhids;
    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(vhids)
      .map((hid) => {
        let h = bikesob[hid];
        let hname = _.lowerCase(h.name);
        if (!nils(sech_hid) && sech_hid == h.hid) return [hid, 1];
        else if (
          !nils(sech_hid) &&
          h.hid.toString().startsWith(sech_hid.toString())
        )
          return [hid, 2];
        else if (hname.startsWith(sear)) return [hid, 3];
        else if (hname.includes(sear)) return [hid, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .map(0)
      .value();
    return filt;
  }, [jstr(vhids), searchtxt, jstr(bikesob)]);

  const pre_pay_loaded =
    stage == 6 &&
    !nils(vault) &&
    auth === true &&
    !nils(aumode) &&
    !_.isEmpty(appcon.tokmap);

  const pay_fn = async () => {
    await cdelay(0.5 * 1e3);
    let token = "DEZ";
    let { amt: amt_usd } = data;
    let lb_params = {
      vault,
      token,
      aumode,
      logbox,
      set_logbox,
      appcon,
      t3con,
    };
    if (cancelref.current) return;
    // console.log("lb_params", lb_params);
    let lb = new LogBox(lb_params, { update_log: true, wait: true });
    lb_ref.current = lb;
    await lb.init();

    let sendto = contractAddress_list["cv2_dnapayrouter"];
    let amt_tok = lb.usd_to_tok(amt_usd);
    let { done, err } = await lb.tok_bal_alw_apv([sendto, amt_tok]);
    if (!nils(err)) return;
    if (cancelref.current) return;

    await cdelay(0.5 * 1e3);
    console.log("lb.amt", amt_tok, lb.amt_tok);

    if (cancelref.current) return;
    let con_class = DNA_PayReceiver;
    // let con_initparams = { aumode, active_account: t3con.active_account };
    let encoded = {
      amt_usd: dec(amt_usd, 2),
      date: iso(),

      challenge_typeid: data.challenge_typeid,
      challenge_name: data.challenge_name,
      challenge_id: data.challenge_id,

      hid: data.hid,
    };
    let args = [service, toeth(lb.amt_tok), token, json_to_base64(encoded)];
    let txinfo = {};
    let msgs = {
      action: "Confirm Transaction for Bid Payment to DNA_Challenge",
      loading: "Processing Payment to DNA_Challenge",
      on_success: (tx) => {
        lb.edit_last_step([
          "success",
          <div class="fr-sc w-full resp-gap-1 text-green-400">
            <TokenIcon token="POL" size="md" />
            <span>Transaction Sent: {trim2(tx.hash, 6, 6)}</span>
          </div>,
          {},
          polytxnidlink(tx.hash),
        ]);
      },
    };

    if (cancelref.current) return;
    let tx = await lb.send_cv2_tx([
      con_class,
      "send_payment",
      args,
      txinfo,
      msgs,
    ]);
    if (cancelref.current) return;

    if (!nils(tx.errmsg)) {
      return;
    }

    if (!nils(tx.hash)) {
      await q_que_txn({
        service,
        hash: tx.hash,
        dets: { servicename: service },
      }).queryFn();
    } else {
      lb.add_step(["error", "Couldn't send transaction to chain"]);
      return;
    }

    // let tx = {
    // };

    // console.log("tx", tx, jstr(tx));
    // console.log("tx.hash", tx.hash);

    // let con = await DNA_PayReceiver.get_contract(con_initparams);
    // tx.parsedlogs = await con.getParsedLogs(tx.logs);
    // console.log("parsedlogs", tx.parsedlogs);

    // let payid = null; // getv(tx.parsedlogs, "0.args.payid");
    let payid = getv(tx.parsedlogs, "0.args.payid");
    if (!nils(payid)) {
      lb.add_step([
        "success",
        <div class="fc-ss w-full resp-gap-1 text-green-400">
          <p>PayId: {trim2(payid, 6, 6)}</p>
          <p>
            Your Challenge should show up in "My Challenges" section below in a
            few minutes
          </p>
        </div>,
        {},
        polytxnidlink(tx.hash),
      ]);
    } else {
      lb.add_step([
        "warning",
        <div class="fc-ss w-full resp-gap-1 text-yellow-400">
          <p>Transaction was sent but couldn't detect a PayID</p>
          <p>"Rest Assured!!" if txns generally clears up in a few minutes</p>
          <p>If thats not the case refund will be issued within an hour</p>
        </div>,
        {},
        polytxnidlink(tx.hash),
      ]);
    }
    await cdelay(4 * 1e3);
    set_logbox((d) => ({ ...d, status: "idle" }));
  };
  const retry_pay = async () => {
    set_logbox({});
    await cdelay(1 * 1e3);
    pay_fn();
  };
  useEffect(() => {
    console.log("pre_pay_loaded", pre_pay_loaded);
    if (!pre_pay_loaded) return;
    if (logbox.status == "loading") return;

    pay_fn();
  }, [stage == 6, pre_pay_loaded]);

  const headcn =
    "font-digi text-acc4 text-left resp-text-1 w-full my-2 text-center";
  const bread_cn =
    "fr-sc resp-p-1 resp-gap-1 font-digi text-white bg-acc4/40 -skew-x-12 rounded-md resp-text-0";
  const bred_break = (
    <FontAwesomeIcon icon={faArrowRightLong} className="text-white" />
  );

  const rvmode = "bike";
  const get_active_cn = (k, op) => {
    const val = getv(data, k);
    const selected = op == val;
    const cn = nils(val) || selected ? "opacity-100" : "opacity-50";

    const text_cn = selected ? "italic text-acc0" : "";
    return [cn, text_cn];
  };
  const setv_data_n_next = (k, v) => {
    set_data((o) => ({ ...o, [k]: v, stage: o.stage + 1 }));
  };

  const actops = useMemo(() => {
    // console.log({ stage });
    if (!_.inRange(stage, 2, 5 + 0.1)) return [];
    let events = getv(qo_eventslist, "data.result", []);
    events = events.map((e) => {
      return { ...e, ...e.cri };
    });
    // console.log("events", events);
    let filt = events;
    if (stage >= 2) {
      filt = _.chain(filt)
        .filter((e) => {
          let h = getv(bikesob, data.hid);
          let ef = getv(e, "cri.entry_filters", {});
          return match_cri_to_bikedata(ef, h);
        })
        .value();
    }
    if (stage >= 3) {
      filt = filt.filter((e) => e.cb == data.cb);
    }
    if (stage >= 4) {
      filt = filt.filter((e) => e.payout == data.payout);
    }
    if (stage >= 5) {
      filt = filt.filter((e) => e.segment == data.segment);
    }
    let k = stage == 2 ? "cb" : stage == 3 ? "payout" : "segment";
    console.log("filt:events", k, filt);
    filt = filt.map((e) => [
      {
        id: e.id,
        typeid: e.typeid,
        name: e.name,
      },
      e[k],
    ]);
    filt = _.uniqBy(filt, (e) => e[1]);
    console.log("filt:final", k, filt);

    return filt;
  }, [stage, jstr(data), qo_eventslist.dataUpdatedAt]);
  const back_allowed = useMemo(() => {
    if (stage >= 6) return false;
    return stage > 0;
  }, [stage]);

  const token = "DEZ";
  // const amt_range = { mi: 5, mx: 30 };
  const amt_range = { mi: 0.01, mx: 100 };
  const [valid_amt, err_amt] = useMemo(() => {
    if (stage != 5) return [false, ""];

    let amt = parseFloat(getv(data, "amt"));
    if (nils(amt) || !(amt > 0)) return [false, "need valid USD amount"];
    amt = parseFloat(amt);

    if (!_.inRange(amt, amt_range.mi, amt_range.mx + 0.01))
      return [false, `enter amt in range ${amt_range.mi} - ${amt_range.mx}`];

    let tokval = usd_to_tok_val(amt, token);

    const bal = getv(aucon, `balance_ob.${token}`);
    if (bal < tokval) return [false, "insufficient balance"];

    return [true, ""];
  }, [stage, data?.amt ?? 0, token, aucon.balance_ob]);

  return (
    <>
      <div class=" h-[45vh] w-full border-lg border-2 border-acc4 bg-acc4/20 rounded-lg backdrop-blur-md resp-p-2">
        {/*         <div class="fr-sc rep-gap-2 resp-p-1">
          <p className="resp-text--1">{jstr(data)}</p>
        </div> */}
        <div class="fr-sc resp-gap-2 resp-p-1 flex-nowrap">
          <div class="fr-sc resp-gap-2 resp-p-1 flex-wrap">
            <Tag
              onClick={() => {
                if (!back_allowed) return;
                let ns = Math.max(0, stage - 1);
                set_data((d) => ({ ...d, stage: ns }));
              }}
              className={twMerge(
                "font-digi -skew-x-12 border border-acc4 text-acc4",
                "fr-sc resp-gap-2",
                back_allowed ? "" : "opacity-10",
              )}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <span class="resp-text-0">Back</span>
            </Tag>

            {stage >= 1 && !nils(data?.hid) && (
              <>
                <div class={twMerge(bread_cn)}>
                  <div class="xs:w-[1rem] xs:h-[1rem] lg:w-[2rem] lg:h-[2rem]">
                    <RVImg rvmode={rvmode} hex_code={`FFFFFF`} />
                  </div>
                  <span>{`Core#${getv(data, "hid")}`}</span>
                </div>
              </>
            )}
            {stage >= 5 ? (
              <>
                {bred_break}

                <div class={twMerge(bread_cn)}>
                  <span>{getv(data, "challenge_name", "")}</span>
                </div>
              </>
            ) : (
              <>
                {stage >= 2 && !nils(data?.cb) && (
                  <>
                    {bred_break}
                    <div class={twMerge(bread_cn)}>
                      <span>{`CB${getv(data, "cb")}`}</span>
                    </div>
                  </>
                )}
                {stage >= 3 && !nils(data?.payout) && (
                  <>
                    {bred_break}
                    <div class={twMerge(bread_cn)}>
                      <span>{get_payout_txt(data?.payout)}</span>
                    </div>
                  </>
                )}
                {stage >= 4 && !nils(data?.segment) && (
                  <>
                    {bred_break}
                    <div class={twMerge(bread_cn)}>
                      <span>{data?.segment}</span>
                    </div>
                  </>
                )}
              </>
            )}
            {stage >= 5 && !nils(data?.amt) && data.confirm_amt && (
              <>
                {bred_break}
                <div class={twMerge(bread_cn)}>
                  <span>${data.amt}</span>
                </div>
              </>
            )}
          </div>
          <div class="flex-1"></div>
          <Tag
            onClick={() => {
              reset_fn();
            }}
            className="fr-sc resp-gap-1 text-acc4 border border-acc4"
          >
            <FontAwesomeIcon icon={faUndoAlt} />
            <span className="resp-text--1">Reset</span>
          </Tag>
        </div>

        {stage == 0 ? (
          <div class="fc-cc resp-gap-2 h-[80%] max-w-[100%] w-[40rem] mx-auto">
            <p className="resp-text-1 text-center text-acc4 font-digi my-2">
              DNA Challenge
            </p>
            <p className="resp-text--1 text-center text-justify font-digi my-2">
              Get ready to ignite your cores' potential! Challenges are here,
              and they're bringing a whole new level of excitement to core
              racing. Think high-stakes bidding, strategic progression, and the
              thrill of the finals. Are you ready to rise through the ranks and
              claim your share of the prize pool?
            </p>
            <p className="resp-text-0 font-digi">The challenge awaits!</p>
            <Tag onClick={start_fn} className="-skew-x-12 bg-acc4/40">
              <span class="resp-text-0 font-digi">Enter Challenge</span>
            </Tag>
            <div class="h-[20%]"></div>
          </div>
        ) : stage == 1 ? (
          <>
            <div class="h-full overflow-hidden fc-ss">
              <p className={headcn}>Select Cores</p>
              <div className="fr-cc w-full resp-gap-1 resp-px-1 resp-my-1">
                <div className="flex-1">
                  <InpText
                    {...{
                      id: `search-cores-challenge`,
                      placeholder: "Search Core",
                      inpprops: {
                        className:
                          "resp-text-1 font-digi bg-tranparent text-acc0 w-full",
                      },
                      contprops: {
                        className:
                          "resp-text-1 font-digi bg-r2dark/20 text-acc0 w-full",
                      },
                      setter: (v) => {
                        console.log("setter", v);
                        if (nils(v)) v = null;
                        set_searchtxt(v);
                      },
                    }}
                  />
                </div>
              </div>
              <div class="h-[30vh] overflow-auto w-full">
                {_.isEmpty(filthids) ? (
                  <div class="resp-text-1 text-center text-yellow-300 font-digi my-2">
                    No Eligible Cores found
                  </div>
                ) : null}
                {filthids.map((hid) => {
                  let h = bikesob[hid];
                  let isunnamed = h.name == "Unnamed DNA Splice";
                  return (
                    <div
                      key={hid}
                      className={twMerge(
                        "bike-row",
                        "bg-r2lig/20 border border-acc4 rounded-md resp-my-2",
                        isunnamed
                          ? "opacity-50 border border-slate-400 bg-slate-400/10"
                          : "",
                      )}
                    >
                      <RaceR2_HRow
                        {...{
                          r: {
                            rvmode,
                          },
                          h,
                          show_entry_confirmation: false,
                          right_flex_jsx: <></>,

                          cont_props: {
                            onClick: (e) => {
                              if (isunnamed) return;
                              setv_data_n_next("hid", hid);
                            },
                            className:
                              "bg-tranparent resp-text--2 cursor-pointer",
                            style: {
                              borderLeft: undefined,
                              borderRight: undefined,
                            },
                          },
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : stage == 2 ? (
          <>
            {qo_eventslist.isLoading ? (
              <Loader01c />
            ) : qiserr(qo_eventslist) ? (
              <p className="text-center text-red-300 resp-text-1 font-digi">
                {qiserr(qo_eventslist)}
              </p>
            ) : qissuccesss(qo_eventslist) && _.isEmpty(actops) ? (
              <>
                <p className="text-center text-yellow-300 resp-text-1 font-digi">
                  No Eligible Challenges for this Bike
                </p>
              </>
            ) : qissuccesss(qo_eventslist) ? (
              <>
                <p className={headcn}>Choose your Racing Distance</p>
                <div class="h-[30vh] fc-cc">
                  <div class="fr-sc resp-gap-4 flex-wrap max-w-[80%] mx-auto">
                    {actops.map(([id, op]) => {
                      const [btn_cn, text_cn] = get_active_cn("feeusd", op);
                      return (
                        <div
                          onClick={() => {
                            setv_data_n_next("cb", op);
                          }}
                          class={twMerge(
                            "cursor-pointer xs:w-[8rem] lg:w-[16rem]",
                            btn_cn,
                          )}
                        >
                          <SkewWrap2>
                            <div
                              className={twMerge(
                                " fr-sc resp-text-2 font-digi",
                                text_cn,
                              )}
                            >
                              <span>{`CB${op}00`}</span>
                            </div>
                          </SkewWrap2>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : stage == 3 ? (
          <>
            <p className={headcn}>Select your Payout</p>
            <div class="h-[30vh] fc-cc">
              <div class="fr-sc resp-gap-4 flex-wrap max-w-[80%] mx-auto">
                {actops.map(([id, op]) => {
                  let k = "payout";
                  const [btn_cn, text_cn] = get_active_cn(k, op);
                  return (
                    <div
                      onClick={() => {
                        setv_data_n_next(k, op);
                      }}
                      class={twMerge(
                        "cursor-pointer xs:w-[8rem] lg:w-[16rem]",
                        btn_cn,
                      )}
                    >
                      <SkewWrap2>
                        <div
                          className={twMerge(
                            " fr-sc resp-text-2 font-digi",
                            text_cn,
                          )}
                        >
                          <span>{get_payout_txt(op)}</span>
                        </div>
                      </SkewWrap2>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : stage == 4 ? (
          <>
            <p className={headcn}>Select your Segment</p>
            <div class="h-[30vh] fc-cc">
              <div class="grid grid-cols-1 resp-gap-1 flex-wrap max-w-[80%] mx-auto">
                {actops.map(([a, op]) => {
                  let k = "segment";
                  const [btn_cn, text_cn] = get_active_cn(k, op);
                  return (
                    <div
                      onClick={() => {
                        // setv_data_n_next(k, op);
                        set_data((d) => ({
                          ...d,
                          segment: op,
                          stage: d.stage + 1,
                          challenge_id: a.id,
                          challenge_typeid: a.typeid,
                          challenge_name: a.name,
                        }));
                      }}
                      class={twMerge(
                        "cursor-pointer w-full resp-p-2 bg-acc4/30 border border-acc4 w-full rounded-md",
                        btn_cn,
                      )}
                    >
                      <div
                        className={twMerge(
                          "text-center font-digi resp-text-1",
                          text_cn,
                        )}
                      >
                        <span>{op}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : stage == 5 ? (
          <>
            <div class="fc-sc w-full">
              <p className={headcn}>Select your Bid Amount [in USD]</p>
              <div class="fr-cs my-[1rem]">
                <div class="fr-cc resp-gap-1">
                  <InpText
                    {...{
                      path: "bidamt",
                      type: "n",
                      label: "Bid Amount",
                      def_val: getv(data, "amt"),
                      contprops: {
                        className: "xs:w-[5rem] lg:w-[10rem]",
                      },
                      inpprops: {
                        className:
                          "xs:text-[1.2rem] lg:text-[2.5rem] w-full font-digi text-center",
                      },
                      setter: (v) => {
                        v = parseFloat(v);
                        if (nils(v)) v = null;
                        v = dec(v, 2);
                        // setv_data_n_next("amt", v);
                        set_data((d) => ({ ...d, amt: v, confirm_amt: false }));
                      },
                    }}
                  />
                </div>
              </div>

              {!nils(err_amt) && (
                <p class="resp-text-1 text-red-300">{err_amt}</p>
              )}

              <Tag
                onClick={() => {
                  if (!valid_amt) return;
                  set_data((d) => ({
                    ...d,
                    confirm_amt: true,
                    stage: d.stage + 1,
                  }));
                }}
                className={twMerge(
                  "fr-sc resp-gap-1 ",
                  valid_amt ? "bg-acc4/50" : "opacity-20",
                )}
              >
                <div class="fr-sc font-digi  resp-text-1">
                  <span> Pay</span>
                  <TokenIcon token="DEZ" size="md" />
                  <span>{token}</span>
                </div>
              </Tag>
            </div>
          </>
        ) : stage == 6 ? (
          <>
            <div class="fc-sc w-full">
              <p className={headcn}>Processing Payment</p>
              {/*               <div class="fc-cc"> <p>{jstr(logbox)}</p> </div> */}
              <div class="w-full max-w-[50rem] mx-auto resp-px-2 my-1 max-h-[30vh] overflow-auto">
                <div class="h-full">
                  {!nils(lb_ref?.current) &&
                    getv(logbox, "steps", []).map((step) => {
                      return (
                        <div class="w-full font-digi my-2">
                          {lb_ref.current.render_step_row(step)}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div class="fr-sc my-1">
                <div class=""></div>
                {_.isEmpty(logbox) ? null : logbox?.status == "error" ? (
                  <Tag
                    onClick={() => {
                      if (logbox.status == "loading") return;
                      retry_pay();
                    }}
                    className={twMerge("bg-red-500 -skew-x-12")}
                  >
                    <span className="resp-text-1 font-digi ">
                      {"Retry Payment"}
                    </span>
                  </Tag>
                ) : logbox?.status == "idle" ? (
                  <Tag
                    onClick={() => {
                      if (logbox.status == "loading") return;
                      reset_fn();
                    }}
                    className={twMerge("bg-acc4/50 -skew-x-12")}
                  >
                    <span className="resp-text-1 font-digi ">
                      {"Start a New Challenge"}
                    </span>
                  </Tag>
                ) : logbox?.status == "loading" ? (
                  <FontAwesomeIcon
                    className={twMerge("resp-text-2 text-acc4 spin-anim")}
                    icon={faSpinner}
                  />
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

const LedgerRow = ({
  leddoc: d,
  onClick = "action_expand",
  name_link_redirect = true,
}) => {
  const appcon = useAppContext();
  const accon = useAccountContext();
  const { tok_to_usd_val } = appcon;
  const { bikesob } = accon;

  const [expand, set_expand] = useState(false);

  const amt_tag = (amt, token, cn, ext = {}) => {
    return (
      <>
        <div class={twMerge("fr-sc resp-gap-1", cn)}>
          <div class="flex-1"></div>
          <span class="font-digi ">{dec(amt, tokdecn2(token))}</span>
          <TokenIcon token={token} size="xs" />
          {
            (ext.hide_usd = true ? null : (
              <div class="lg:min-w-[3rem]">
                {<span>[{dec(tok_to_usd_val(amt, token), 0)}USD]</span>}
              </div>
            ))
          }
        </div>
      </>
    );
  };
  const timefor = "DD,MMM HH:mm";
  const spans = {
    c1: "",
    c2: "",
    c3: "",
  };
  const h = getv(bikesob, getv(d, "ext.hid"));
  const opext = { hide_usd: true };

  const scroll_ref = useRef(null);
  useEffect(() => {
    const fn = () => {
      const cont = scroll_ref.current;
      if (!cont) return;
      // console.log("cont", cont, cont?.scrollHeight);
      // cont.scrollTop = cont.scrollHeight;
      cont.scrollTo({ top: cont.scrollHeight, behavior: "smooth" });
    };

    if (expand) setTimeout(fn, 0.3 * 1e3);
  }, [expand]);

  const chlg_name_status_row = useMemo(() => {
    if (nils(d)) return null;
    return (
      <div class="fr-sc w-full resp-gap-1 resp-px-4">
        <p class="resp-text--2 font-digi w-[70%] text-acc4">
          {getv(d, "challenge_name", "")}
        </p>
      </div>
    );
  }, [d?.name, d?.status]);
  return (
    <>
      <div
        class={twMerge(
          "resp-p-2 bg-acc4/20 w-full rounded-lg border border-acc4",
          "my-1 cursor-pointer",
          expand ? "mb-2" : "",
        )}
      >
        <div
          onClick={(e) => {
            if (!onClick) return;
            if (_.isString(onClick) & onClick.startsWith("action_")) {
              if (onClick == "action_expand") {
                set_expand(!expand);
              }
            } else if (_.isFunction(onClick)) {
              onClick(e);
            }
          }}
        >
          <div class="grid grid-cols-12">
            <div class="fc-ss resp-gap-1 col-span-2">
              <HashToColors hash={d.id} />
              <div
                className={twMerge(
                  "w-full bg-r2dark/50 font-digi resp-text--1 text-acc4 border border-acc4 resp-px-2 resp-py-1 rounded-md uppercase text-center",
                  status_led_cnmap[d.status],
                )}
              >
                {d.status == "refunding_dust" ? "Refund" : d.status}
              </div>
            </div>
            <div class="flex-1 col-span-5">
              {name_link_redirect ? (
                <Link to={`/challenge/${d.challenge_id}`}>
                  {chlg_name_status_row}
                </Link>
              ) : (
                chlg_name_status_row
              )}

              <div class="fr-sc w-full resp-gap-1 resp-px-4">
                <div class="w-[3rem] ">
                  <RVImg
                    rvmode={getv(d, "challenge_rvmode", "bike")}
                    hex_code={`FFFFFF`}
                  />
                </div>
                {!nils(getv(d, "ext.hid")) && (
                  <span class="font-digi resp-text--1">
                    Core#{getv(d, "ext.hid")}
                  </span>
                )}
                {!nils(h) && (
                  <span class="font-digi resp-text--1">{h.name}</span>
                )}
              </div>

              {!nils(d.validate_err_msg) && (
                <div class="fr-sc mt-2 text-red-400 resp-gap-1">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="text-red-400"
                  />
                  <p class="font-mon resp-text--1">{d.validate_err_msg}</p>
                </div>
              )}
              {d.status == "active" && d.challenge_status == "active" && (
                <div class="grid grid-cols-11 resp-text--1 resp-gap-2">
                  <div class="col-span-3">
                    <p class="font-digi">
                      <span>{" Currently In: "}</span>
                      <span className="text-acc4">
                        {pure_fee_txt(getv(d, "track.feek"))}
                      </span>
                    </p>
                  </div>
                  <div class="col-span-8">
                    <div class="fc-ss">
                      {nils(getv(d, "track.u_mode")) ? null : (
                        <>
                          <span className="text-green-400">
                            Upgrade: {getv(d, "track.u_mode")}
                          </span>
                          <span className="text-green-400">{`${dec(getv(d, "track.tuval", 0), 2)}/${dec(getv(d, "track.u_req"), 2)}`}</span>
                        </>
                      )}

                      {nils(getv(d, "track.d_mode")) ? null : (
                        <>
                          <span className="text-red-400">
                            Downgrade: {getv(d, "track.d_mode")}
                          </span>
                          <span className="text-red-400">{`${dec(getv(d, "track.tdval", 0), 2)}/${dec(getv(d, "track.d_req"), 2)}`}</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div class="col-span-4">
                    <div class="fc-ss"></div>
                  </div>
                </div>
              )}
            </div>
            <div class="col-span-5 grid grid-cols-3 resp-gap-1 resp-text--1">
              <div
                onClick={() => open_polylink(d.txid)}
                class="fr-cc cursor-pointer"
              >
                <p className="font-digi w-full text-right">Bid:</p>
                <TokenIcon token={"POL"} size="md" />
              </div>
              <div className=" fc-cc">
                <span>{iso_format(d.date, "DD,MMM hh:mm")}</span>
              </div>
              {amt_tag(d.init_amt, d.token, "")}
              {d.status == "active" ? (
                <>
                  <div class="fr-cc">
                    <p className="font-digi w-full text-right">Balance:</p>
                  </div>
                  <div className=" fc-cc">
                    <span>{iso_format(d.date, timefor)}</span>
                  </div>
                  {amt_tag(d.rem_amt, d.token, "text-green-400")}
                </>
              ) : d.status == "exit" ? (
                <>
                  <div
                    onClick={() => open_polylink(getv(d, "refund.hash"))}
                    class="fr-cc cursor-pointer"
                  >
                    <p className="font-digi w-full text-right">Refunded:</p>
                    <TokenIcon token={"POL"} size="md" />
                  </div>
                  <div className=" fc-cc">
                    <span>{iso_format(d.date, timefor)}</span>
                  </div>
                  {amt_tag(d?.refund?.amt, d.token, "text-green-400")}
                </>
              ) : d.status == "refunding_dust" ? (
                <>
                  <div class="fr-cc cursor-pointer resp-gap-1">
                    <FontAwesomeIcon icon={faSpinner} />
                    <p className="font-digi w-full text-right resp-text--2">
                      Will Refund:
                    </p>
                  </div>
                  <div className=" fc-cc">
                    <span>{iso_format(d.date, timefor)}</span>
                  </div>
                  {amt_tag(d?.refund?.amt, d.token, "text-green-400")}
                </>
              ) : null}

              <>
                <div class="fr-cc">
                  <p className="font-digi w-full text-right">Profits:</p>
                </div>
                <div className=" fc-cc"></div>
                {amt_tag(getv(d, "race_prizes", 0), d.token, "text-green-400")}
              </>
            </div>
          </div>

          {expand && (
            <>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                class="bg-r2dark/60 rounded-md resp-text--1 resp-p-2"
              >
                {_.isEmpty(d.logs) ? (
                  <p>No Logs</p>
                ) : (
                  <>
                    <div ref={scroll_ref} class="h-[25rem] overflow-auto">
                      <table
                        className={twMerge(
                          tablecn.table,
                          "w-full thintdrowp2-table-base border-transparent",
                        )}
                      >
                        <thead className="sticky top-0 bg-r2dark/20 backdrop-blur-md">
                          <tr className="font-digi text-acc4">
                            <td>type</td>
                            <td>date</td>
                            <td>info</td>
                            <td>action</td>
                            <td>Bef_Bal</td>
                            <td>Aft_Bal</td>
                          </tr>
                        </thead>
                        <tbody>
                          {d.logs.map((e) => {
                            return (
                              <tr
                                className={twMerge(
                                  "",
                                  e.type == "upgrade" ? "bg-green-400/20" : "",
                                  e.type == "downgrade" ? "bg-red-400/20" : "",
                                )}
                                key={e.id}
                              >
                                <td>{e.type}</td>
                                <td>{e.date}</td>
                                <td>
                                  <div class="fc-ss">
                                    {e.type == "race_entry" ? (
                                      <>
                                        <span>race: {e.rid}</span>
                                        <div class="fr-sc resp-gap-1">
                                          <span>fee:</span>
                                          {amt_tag(e.fee, d.token, "", opext)}
                                        </div>
                                      </>
                                    ) : e.type == "race_payout" ? (
                                      <>
                                        <span>race: {e.rid}</span>
                                        <div class="fr-sc resp-gap-1">
                                          <span>prize:</span>
                                          {amt_tag(e.prize, d.token, "", opext)}
                                        </div>

                                        {!nils(getv(e, "ext.tuval_aft")) && (
                                          <div class="fr-sc resp-gap-1 text-green-400">
                                            <span>U:</span>
                                            <span>
                                              {dec(getv(e, "ext.tuval_bef"), 2)}
                                            </span>
                                            <FontAwesomeIcon
                                              icon={faArrowRight}
                                            />
                                            <span>
                                              {dec(getv(e, "ext.tuval_aft"), 2)}
                                            </span>
                                          </div>
                                        )}

                                        {!nils(getv(e, "ext.tdval_aft")) && (
                                          <div class="fr-sc resp-gap-1 text-red-400">
                                            <span>D:</span>
                                            <span>
                                              {dec(getv(e, "ext.tdval_bef"), 2)}
                                            </span>
                                            <FontAwesomeIcon
                                              icon={faArrowRight}
                                            />
                                            <span>
                                              {dec(getv(e, "ext.tdval_aft"), 2)}
                                            </span>
                                          </div>
                                        )}
                                      </>
                                    ) : e.type == "upgrade" ? (
                                      <div class="fr-sc resp-gap-2">
                                        <span class="">{e.from}</span>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                        <span class="resp-text-1 text-green-500">
                                          {e.to}
                                        </span>
                                      </div>
                                    ) : e.type == "downgrade" ? (
                                      <div class="fr-sc resp-gap-2">
                                        <span class="">{e.from}</span>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                        <span class="resp-text-1 text-green-500">
                                          {e.to}
                                        </span>
                                      </div>
                                    ) : (
                                      <span>--</span>
                                    )}
                                  </div>
                                </td>
                                <td>{""}</td>
                                <td>
                                  {amt_tag(e.bef_bal, d.token, "", opext)}
                                </td>
                                <td>
                                  {amt_tag(e.aft_bal, d.token, "", opext)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const VaultLedgerSection = ({ vault, ext_q = {} }) => {
  const [page, set_page] = useState(0);
  const limit = 10;

  const [qoledger] = useQueries([
    q_challenge_ledger(
      { vault, page, limit, ...ext_q },
      {
        enabled: !nils(vault),
        refetchInterval: 30 * 1e3,
      },
    ),
  ]);
  const ledger = useMemo(() => {
    let ledger = getv(qoledger, "data.result", []);

    return ledger;
  }, [qoledger.dataUpdatedAt]);

  const topref = useRef(null);
  const scroll_to_top = () => {
    if (!topref.current) return;
    topref.current.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    scroll_to_top();
  }, [qoledger.dataUpdatedAt]);
  const pageiconcn =
    "resp-p-2  border border-acc4 bg-acc4/30 hover:bg-acc4/60 xs:rounded-full xs:rounded-full";

  const page_mover = (
    <div class="fr-sc resp-gap-1">
      <div class="flex-1"></div>
      <Tag onClick={() => set_page(0)} className={twMerge(pageiconcn)}>
        <FontAwesomeIcon className="text-white" icon={faCaretLeft} />
        <FontAwesomeIcon className="text-white" icon={faCaretLeft} />
      </Tag>
      <Tag
        onClick={() => set_page(Math.max(0, page - 1))}
        className={twMerge(pageiconcn)}
      >
        <FontAwesomeIcon className="text-white" icon={faChevronLeft} />
      </Tag>
      <p class="resp-text-1 font-digi">{`Page ${page + 1}`}</p>

      <Tag onClick={() => set_page(page + 1)} className={twMerge(pageiconcn)}>
        <FontAwesomeIcon className="text-white" icon={faChevronRight} />
      </Tag>
    </div>
  );

  return (
    <>
      <div class="w-full my-2 fc-cc">
        <p ref={topref} class="resp-text-1 text-center font-digi my-2">
          {/*           My Challenges */}
        </p>
        <div class="fr-sc resp-gap-1 w-full my-2">
          <HashToColors
            {...{
              hash: vault,
              contcn: "grid-cols-3",
              cn: "w-[0.5rem]",
            }}
          />
          <p class="resp-text--2 text-center font-digi my-2">
            {trim2(vault, 6, 3)}
          </p>
          <div class="flex-1"></div>
          {page_mover}
        </div>
        {qoledger.isLoading ? (
          <FontAwesomeIcon
            icon={faSpinner}
            className="text-acc4 resp-text-3 spin-anim"
          />
        ) : qiserr(qoledger) ? (
          <p class="resp-text-1 text-red-400">{qiserr(qoledger)}</p>
        ) : qissuccesss(qoledger) ? (
          <div class="w-full">
            {_.isEmpty(ledger) ? (
              <p class="resp-text-1 text-yellow-400">No Challenges on Ledger</p>
            ) : (
              <>
                {ledger.map((led) => {
                  return <LedgerRow {...{ leddoc: led }} />;
                })}
                <div class="fr-sc w-full my-2">
                  <div class="flex-1"></div>
                  {page_mover}
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <span>fallback</span>
          </>
        )}
      </div>
    </>
  );
};
