import { getv, iso, jparse, jstr, nano, nils, tofeth } from "../../utils/utils";
import { contractAddress_list } from "../constants";
import { ethers } from "ethers";
import abi from "./abis/DNA_RaceStake.json";
import { t3_contract_call } from "../contract_funcs";
import _ from "lodash";
import { polygon } from "thirdweb/chains";
import { useThirdWebLoginContext } from "../../views/ThirdWebLogin";

const k = "racestake_cv2";

const abi_methods_map = _.keyBy(abi, "name");

class Contract {
  constructor({ contractAddress, provider, signer, aumode, ...ext }) {
    this.provider = provider;
    this.signer = signer;
    // this.abi = abi;
    // this.abi_methods_map = abi_methods_map;
    this.contractAddress = contractAddress;
    this.contract = new ethers.Contract(this.contractAddress, abi, signer);
    this.aumode = aumode;
    if (aumode == "thirdweb") {
      this.active_account = ext.active_account;
    }
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);

      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      logrows.push(o);
    }
    return logrows;
  }

  async u_enterRace(rid, hid, token, info = {}) {
    if (this.aumode == "thirdweb") {
      let tx = await t3_contract_call(
        this.contractAddress,
        "u_enterRace",
        [rid, hid, token],
        "txn",
        info.wait === false ? false : true,
        { abi_methods_map, active_account: this.active_account },
      );
      console.log(`${k}:u_enterRace`, tx);
      return tx;
    } else {
      let gasinfo = {};
      let tx = await this.contract.u_enterRace(rid, hid, token, gasinfo);
      console.log(`${k}:u_enterRace`, tx.hash);
      if (info.wait !== false) tx = await tx.wait();
      return tx;
    }
  }
}

const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_contract = async (ext = {}) => {
  const aumode = getv(ext, "aumode");
  const contractAddress = get_contract_address();
  if (aumode == "wallet") {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = ext.nosigner === true ? null : await provider.getSigner();
    const runner = new Contract({ contractAddress, provider, signer, aumode });
    return runner;
  } else if (aumode == "thirdweb") {
    const provider = new ethers.JsonRpcProvider(polygon.rpc);
    const signer = null;
    const runner = new Contract({
      contractAddress,
      provider,
      signer,
      aumode,
      active_account: ext.active_account,
    });
    return runner;
  }
  return null;
};

export const RaceStake = {
  k,
  get_contract,
  get_contract_address,
};
